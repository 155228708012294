import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Card } from "antd";
import dayjs from "dayjs";
import {
  getHistorySummary,
  getSymptomsSummary,
  getDiagnosisSummary,
  getInvestigationsSummary,
  getDrugs,
  getEffects,
} from "./../../../Api/index.js";

const Summary = () => {
  const selected = useSelector((state) => state.patients.selected);
  console.log(selected);
  const [historyData, setHistoryData] = useState([]);
  const [symptomsData, setSymptomsData] = useState([]);
  const [examinationData, setExaminationData] = useState([]);
  const [investigationData, setInvestigationData] = useState([]);
  const [drugregimeData, setDrugRegimeData] = useState([]);
  const [adverseeffectsData, setAdverseEffectsData] = useState([]);

  const getData = async (apiFunc, setData, stateKey) => {
    if (selected) {
      try {
        const response = await apiFunc(selected.ltbi_no);
        setData(response.data);
      } catch (error) {
        console.error(`Error fetching ${stateKey} data: `, error);
      }
    }
  };

  useEffect(() => {
    if (selected) {
      const dataFetchList = [
        {
          apiFunc: getHistorySummary,
          setData: setHistoryData,
          stateKey: "historyData",
        },
        {
          apiFunc: getSymptomsSummary,
          setData: setSymptomsData,
          stateKey: "symptomsData",
        },
        {
          apiFunc: getDiagnosisSummary,
          setData: setExaminationData,
          stateKey: "examinationData",
        },
        {
          apiFunc: getInvestigationsSummary,
          setData: setInvestigationData,
          stateKey: "investigationData",
        },
        {
          apiFunc: getDrugs,
          setData: setDrugRegimeData,
          stateKey: "drugregimeData",
        },
        {
          apiFunc: getEffects,
          setData: setAdverseEffectsData,
          stateKey: "adverseeffectsData",
        },
      ];

      dataFetchList.forEach(({ apiFunc, setData, stateKey }) => {
        getData(apiFunc, setData, stateKey);
      });
    }
  }, [selected]);

  const getColumnTitles = (data, keyNameArray) =>
    data.length
      ? keyNameArray.map((key) => {
          if (key === "created_at") {
            return {
              title: key
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
              dataIndex: key,
              key,
              render: (text) => dayjs(text).format("YYYY-MM-DD"),
            };
          }
          return {
            title: key
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
            dataIndex: key,
            key,
          };
        })
      : [];

  const generalData = selected
    ? [
        {
          key: "1",
          screening_no: selected.screening_no,
          name: selected.name,
          dob: dayjs(selected.dob).format("YYYY-MM-DD"),
          sex: selected.sex,
          mobile_phone: selected.mobile_phone,
          home_phone: selected.home_phone,
          permanent_address: selected.permanent_address,
          residential_address: selected.residential_address,
          hiv_positive: selected.hiv_positive,
        },
      ]
    : [];

  const generalColumns = getColumnTitles(
    generalData,
    Object.keys(generalData[0]).filter((key) => key !== "key")
  );

  const historyColumns = getColumnTitles(historyData, [
    "medical_history",
    "surgical_history",
    "drug_allergies",
    "food_allergies",
    "smoking",
    "alcohol",
    "illicit",
  ]);
  const symptomsColumns = getColumnTitles(symptomsData, [
    "symptom",
    "created_at",
  ]);

  const examinationColumns = getColumnTitles(examinationData, [
    "hiv",
    "weight",
    "height",
    "general",
    "cardio",
    "respiratory",
    "other",
  ]);

  const investigationColumns = getColumnTitles(investigationData, [
    "investigation",
    "type",
    "report_no",
    "result",
    "unit",
    "result_date",
    "test_date",
  ]);

  const drugregimeColumns = getColumnTitles(drugregimeData, [
    "regime",
    "frequency",
    "duration",
    "drug_Name",
    "dose",
    "number_of_tabs",
  ]);

  const adverseeffectsColumns = getColumnTitles(adverseeffectsData, [
    "effect",
    "action",
  ]);

  const renderCardWithTable = (title, data, dummyData, columns) => {
    return (
      <Card title={title}>
        {!data.length ? (
          <Table dataSource={dummyData} columns={columns} pagination={false} />
        ) : (
          <Table dataSource={data} columns={columns} pagination={false} />
        )}
      </Card>
    );
  };

  return (
    <div>
      <Card title="General Information">
        <Table
          dataSource={generalData}
          columns={generalColumns}
          pagination={false}
        />
      </Card>
      <br />
      <br />

      <Card title="History">
        <Table
          dataSource={historyData}
          columns={historyColumns}
          pagination={false}
        />
      </Card>
      <br />
      <br />

      <Card title="Symptoms">
        <Table
          dataSource={symptomsData}
          columns={symptomsColumns}
          pagination={false}
        />
      </Card>
      <br />
      <br />

      <Card title="Examination">
        <Table
          dataSource={examinationData}
          columns={examinationColumns}
          pagination={false}
        />
      </Card>
      <br />
      <br />

      <Card title="Investigation">
        <Table
          dataSource={investigationData}
          columns={investigationColumns}
          pagination={false}
        />
      </Card>
      <br />
      <br />

      <Card title="Drug Regime">
        <Table
          dataSource={drugregimeData}
          columns={drugregimeColumns}
          pagination={false}
        />
      </Card>
      <br />
      <br />

      <Card title="Adverse Effects">
        <Table
          dataSource={adverseeffectsData}
          columns={adverseeffectsColumns}
          pagination={false}
        />
      </Card>
      <br />
      <br />
    </div>
  );
};

export default Summary;
