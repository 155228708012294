import { Table, Button, Modal, Card, Input, Form, Steps } from "antd";
import FormInput from "Components/form/FormInput";
import FormSelect from "Components/form/FormSelect";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { OUTCOME } from "Constants";

const Outcome = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [presumptive, setPresumptive] = useState(0);

  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const showConfirmationModal = () => {
    setConfirmationVisible(true);
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      // Handle the finish treatment logic here
      console.log("Treatment finished.");
    }

    setConfirmationVisible(false);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    if (selected) {
    } else {
    }
  };

  useEffect(() => {
    if (selected) {
      Object.entries(selected).forEach(([key, value]) => {
        form.setFieldValue(key, key == "dob" ? dayjs(value) : value);
      });
      setPresumptive(selected["presumptive_category"]);
    } else {
      form.resetFields();
    }
  }, [selected]);

  useEffect(() => {
    form.setFieldValue("presumptive_category_sub", null);
  }, [presumptive]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <Card className="p-2">
            <div className="row">
              <div className="col-md-6">
                <FormSelect label="Outcome" name="outcome" options={OUTCOME} />
              </div>
              <div className="col-md-6">
                <FormInput label="Past TB history" name="date_out" />
              </div>
              <div className="col-md-6">
                <FormInput label="Comment" name="comment" type="textarea" />
              </div>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" onClick={showConfirmationModal}>
                  Finish Treatment
                </Button>
              </Form.Item>
              {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Finish Treatment
                </Button>
              </Form.Item> */}
            </div>
          </Card>
        </div>
        <div className="col-md-2"></div>
      </div>

      <Modal
        title="Confirmation"
        visible={confirmationVisible}
        onOk={() => handleConfirmation(true)}
        onCancel={() => handleConfirmation(false)}
        okText="Yes"
        cancelText="No"
      >
        Are you sure you want to finish the treatment?
      </Modal>
    </Form>
  );
};

export default Outcome;
