import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'App.css'
import AppRouter from './AppRouter';
import { message } from 'antd';
import { useSelector } from 'react-redux';

const items = [
  {
    label: 'Value 1',
    key: '1',

  },
  {
    label: 'Value 2',
    key: '2',

  },

];

const handleMenuClick = (e) => {

  console.log('click', e);
};
const menuProps = {
  items,
  onClick: handleMenuClick,
};

const App = () => {
  const alert = useSelector((state) => state.layout.alert);
  const [messageApi, contextHolder] = message.useMessage();
  const info = () => {
    messageApi.open({
      type: alert.type ?? 'info',
      content: alert.message ?? '',
    });
  };
  useEffect(() => {
    if (alert) {
      info();
    }
    console.log(alert);
  }, [alert])
  return (
    <>
      {contextHolder}
      <AppRouter />
    </>
  );
};

export default App;
