// import React from "react";

// const Users = () => {
//   return <div>Users</div>;
// };

// export default Users;
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Form, Input, Select, Tag } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { Card } from "antd";
import { DatePicker } from "antd";
import { useForm } from "antd/es/form/Form";

import FormSelect from "../Components/form/FormSelect";
import { createLevel, getAllLevels } from "Api";
import { getUsers } from "Api";
import { getPermissions } from "Api";
import { signUp } from "Api";

// import getPageLabels from "../../Utils/Label.service";
// import { ADDNEWUSER_PAGE_LABELS } from "../../Utils/Labels/AddNewUser.page.labels";
// import { i18n } from "../../App";
// import { responseAlert, failureAlert } from "../modules/Alert";
// import UserRules from "./validations/user_validation_rules";
// import { addUser, getOrgUnits, getUserRoles } from "../../Api";

const items = [
  {
    label: "Value 1",
    key: "1",
  },
  {
    label: "Value 2",
    key: "2",
  },
];

const { RangePicker } = DatePicker;

function Users() {
  const [role, setRole] = useState(0);
  const [area, setArea] = useState(0);
  const [users, setUsers] = useState([]);
  const [permisions, setPermissions] = useState([]);

  const [levels, setLevels] = useState([{ value: 1, text: "Lvl1" }]);
  const [orgUnits, setOrgUnits] = useState([]);
  const [form] = useForm();
  const [userform] = useForm();

  // const { language } = useContext(i18n);
  // const rules = UserRules();
  // const lang = useMemo(
  //   () => getPageLabels(ADDNEWUSER_PAGE_LABELS, language.language),
  //   [language]
  // );

  const fetchUsers = () => {
    getAllLevels().then((result) => {
      const data = result?.data?.map((level) => ({
        value: level.id,
        text: level.user_level,
        permissions: level.permissions,
      }));
      if (!data) {
        return;
      }
      console.log({ allLevels: data });
      setLevels(data);

      getUsers().then((result) => {
        const dat = (result?.data.map((res) => { res.level = (data.filter((dat) => dat.value == res.user_level))[0].text; return res; }));
        setUsers(dat);
      });

      getPermissions().then((res) => {
        setPermissions(res?.data);
      });

    });
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleMenuClick = (e) => {
    console.log("click", e);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  // const onFinish = (values) => {
  //   // Handle form submission here
  //   values.area_type = 1;

  //   addUser(values)
  //     .then((response) => {
  //       responseAlert(
  //         response.status,
  //         language.language,
  //         lang.alert_save_success
  //       );
  //     })
  //     .catch((error) => {
  //       // Catch any network errors
  //       failureAlert(error, language.language);
  //     });
  // };

  // useEffect(() => {
  //   getUserRoles().then((out) => {
  //     const levels = out.data.data.map((itm) => {
  //       return { value: itm.id, text: itm.role };
  //     });
  //     setRoles(levels);
  //   });
  //   getOrgUnits().then((out) => {
  //     const orgs = out.data.data;
  //     console.log("ORGS", orgs);
  //     setOrgUnits(
  //       orgs.map((org) => {
  //         return {
  //           value: org.id,
  //           text: org.phm + "(" + org.rdhs + "/" + org.moh + ")",
  //         };
  //       })
  //     );
  //   });
  // }, []);
  const onFinish = (values) => {
    console.log("Success:", values);
    createLevel(values).then((out) => {
      console.log("Screening Out", out);
    });
  };

  const createUser = (values) => {
    console.log("Create user val", values);
    userform.resetFields();
    signUp(values).then((out)=>{fetchUsers()})
  }

  return (
    <div>
      <div className="">
        <div className="">
          <div>
            <Card className="p-2">
              <Form
                // onFinish={onFinish}
                autoComplete="off"
                name="basic"
                onFinish={createUser}
                // onFinishFailed={onFinishFailed}
                form={userform}
                layout="vertical"
              >
                <div className="row">
                  <div className="col-md-4">
                    <div className="fos-card-heading font-bold">
                      Users
                    </div>
                    <div>
                      {users && users.length > 0 ? users.map((user) => <Card>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>{user.name}</div> <Tag color="magenta">{user.level}</Tag>
                        </div>
                        <div>{user.email}</div>
                      </Card>) : null}
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="d-flex justify-content-between">
                      <div className="fos-card-heading font-bold">
                        Add New User
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <Form.Item
                              label="username"
                              name="username"
                            // rules={Object.values(rules?.user_name ?? {})}
                            >
                              <Input
                                prefix={
                                  <UserOutlined className="site-form-item-icon" />
                                }
                                placeholder="username"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <Form.Item
                              label="name"
                              name="name"
                            // rules={Object.values(rules?.name ?? {})}
                            >
                              <Input placeholder="name" />
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <Form.Item
                              label="email"
                              name="email"
                            // rules={Object.values(rules?.name ?? {})}
                            >
                              <Input placeholder="email" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <Form.Item
                              label="password"
                              name="password"
                            // rules={Object.values(rules?.password ?? {})}
                            >
                              <Input.Password
                                prefix={
                                  <LockOutlined className="site-form-item-icon" />
                                }
                                type="password"
                                placeholder="password"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Confirm Password"
                              name="confirm_password"
                            // rules={Object.values(
                            //   rules?.confirm_password ?? {}
                            // )}
                            >
                              <Input.Password
                                prefix={
                                  <LockOutlined className="site-form-item-icon" />
                                }
                                type="password"
                                placeholder="Confirm Password"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div>
                              <FormSelect
                                name="user_level"
                                label="User Level"
                                options={levels}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <Form.Item
                              label="mobile"
                              name="mobile"
                            // rules={Object.values(rules?.name ?? {})}
                            >
                              <Input placeholder="mobile" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-6 d-flex align-items-end ">
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="fos-btn-primary mb-1 ml-4"
                          // onClick={onFinish}
                          >
                            save
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
          <Card className="p-2 mt-4">
            <Form
              onFinish={onFinish}
              form={form}
              // onFinish={onFinish}
              autoComplete="off"
              name="basic"
              // onFinish={onFinish}
              // onFinishFailed={onFinishFailed}

              layout="vertical"
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="fos-card-heading font-bold">
                    Levels
                  </div>
                  <div>
                    {levels && levels.length > 0 ? levels.map((level) => <Card onClick={() => console.log(level)}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{level.text}</div>
                      </div>
                    </Card>) : null}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="d-flex justify-content-between">
                    <div className="fos-card-heading font-bold">
                      Add New Level
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <Form.Item
                            label="Level"
                            name="level"
                          // rules={Object.values(rules?.name ?? {})}
                          >
                            <Input placeholder="level" />
                          </Form.Item>
                        </div>
                        <div className="col-md-4">
                          <Form.Item name="values" valuePropName="checked">
                            <Checkbox.Group options={permisions.map((perm) => { return { label: perm.permission, value: perm.id } })} onChange={() => { }} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6 d-flex align-items-end ">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="fos-btn-primary mb-1 ml-4"
                        // onClick={onFinish}
                        >
                          Add
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Users;
