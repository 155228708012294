import { Table, Button, Modal, Card, Input, Form, Steps } from "antd";
import FormInput from "Components/form/FormInput";
import { DISTRICTS } from "Constants";
import FormSelect from "Components/form/FormSelect";
import FormRadioGroup from "Components/form/FormRadioGroup";
import { GENDER } from "Constants";
import FormMulti from "Components/form/FormMulti";
import { CATEGORIES } from "Constants";
import { SYMPTOMS } from "Constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { createScreening } from "Api";
import dayjs from "dayjs";
import { CLOSE_CATEGORY } from "Constants";
import { CLINICAL_RISK } from "Constants";
import { VULNERABLE } from "Constants";
import { PRESENTPAST } from "Constants";
import { YESNO } from "Constants";

import { createDiagnosis, getDiagnosis } from "Api";
import { DatePicker } from "antd";
const Examinations = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);

  const [presumptive, setPresumptive] = useState(0);

  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);

  const calculateBmi = (weight, height) => {
    if (height > 0) {
      const heightInMeters = height / 100;
      return (weight / (heightInMeters * heightInMeters)).toFixed(2);
    }
    return 0;
  };
  // const onFinish = (values) => {
  //   console.log("Success:", values);
  //   if (selected) {
  //   } else {
  //   }
  // };
  const onFinish = (values) => {
    console.log("Success:", values);
    if (selected && values) {
      const body = {
        // screening_no: selected.screening_no,
        // pin: selected.pin,
        ltbi_no: selected.ltbi_no,
        // diagnose_date: values.diagnose_date,
        // registration_date: values.registration_date,
        hiv: values.hiv,
        ltbi_category: values.ltbi_category,
        weight: values.weight,
        height: values.height,
        general: values.general,
        cardio: values.cardio,
        respiratory: values.respiratory,
        other: values.other,
      };

      createDiagnosis(body).then((data) => {
        console.log(data);
      });
    } else {
    }
  };

  useEffect(() => {
    if (selected) {
      console.log("SELECTED", selected);

      getDiagnosis({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data?.at(0);
        if (!data) {
          return;
        }
        console.log("Diagnosis OUT", data);
        Object.entries(data).forEach(([key, value]) => {
          form.setFieldValue(key, key == "dob" ? dayjs(value) : value);
          if(key == 'weight'){
            setWeight(value);
          }
          else if(key == 'height'){
            setHeight(value);
          }
          // form.setFieldValue(key, value);
        });
      });
    } else {
      form.resetFields();
    }
  }, [selected]);

  useEffect(() => {
    form.setFieldsValue({
      bmi: calculateBmi(weight, height), 
    });
    console.log("set Bim");
  }, [weight, height]);

  // useEffect(() => {
  //   if (selected) {
  //     console.log("SELECTED", selected);
  //     Object.entries(selected).forEach(([key, value]) => {
  //       form.setFieldValue(key, key == "dob" ? dayjs(value) : value);
  //     });
  //     setPresumptive(selected["presumptive_category"]);
  //   } else {
  //     form.resetFields();
  //   }
  // }, [selected]);

  // useEffect(() => {
  //   form.setFieldValue("presumptive_category_sub", null);
  // }, [presumptive]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-3">
            {/* <div className="col-md-3"> */}
            <FormRadioGroup label="HIV Positive" name="hiv" options={YESNO} />
            {/* </div> */}
            {/* <Form.Item label="Diagnose Date" name="diagnose_date">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item> */}
            {/* <FormInput label="Diagnose Date" name="diagnose_date" type="date" /> */}
          </div>
          <div className="col-md-3">
            <FormInput label="BMI (kg/m²)" name="bmi" type="text" readOnly/>       
          </div>
          <div className="col-md-3">
            {/* <Form.Item label="Registration Date" name="registration_date">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item> */}
            {/* <FormInput
              label="Registration Date"
              name="registration_date"
              type="date"
            /> */}
          </div>
          <div className="col-md-3">
            {/* <FormRadioGroup label="HIV Positive" name="hiv" options={YESNO} /> */}
          </div>
          <div className="col-md-3">
           <FormRadioGroup
              label="LTBI Category"
              name="ltbi_category"
              options={YESNO}
            /> 
          </div>
          <div className="col-md-3">
            <FormInput label="Weight (kg)" name="weight" type="number" onChange={(e) => setWeight(parseFloat(e.target.value))}/>
          </div>
          <div className="col-md-3">
            <FormInput label="Height (cm)" name="height" type="number" onChange={(e) => setHeight(parseFloat(e.target.value))}/>
          </div>
          <div className="col-md-3">
            <FormInput label="General Examinations" name="general" />
          </div>
          <div className="col-md-3">
            <FormInput label="Cardiovascular system" name="cardio" />
          </div>
          <div className="col-md-3">
            <FormInput label="Respiratory system" name="respiratory" />
          </div>
          <div className="col-md-3">
            <FormInput label="Other findings" name="other" />
          </div>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </div>
      </Card>
    </Form>
  );
};

export default Examinations;
