import { configureStore } from '@reduxjs/toolkit';
import layoutReducer from '../Reducers/Layout'
import screeningReducer from '../Reducers/Screening'
import patientsReducer from '../Reducers/Patients'

export const store = configureStore({
    reducer: {
        layout: layoutReducer,
        screening: screeningReducer,
        patients: patientsReducer
    },
})