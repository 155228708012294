import { Col, Row, Input, Card, Button, Form, Radio } from "antd";
import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Table from "react-bootstrap/Table";
import { getScreeningAgeReport } from "Api";
import { getScreeningAgeReportPositive } from "Api";
import { getPatientAgeReport } from "Api";

import image1 from "../../../Assests/logo_lk.png";
import image2 from "../../../Assests/LTBI.png";

import FormSelect from "Components/form/FormSelect";
import { REPORT_DISTRICTS } from "Constants";
import { REPORT_TYPE } from "Constants";
import FormRadioGroup from "Components/form/FormRadioGroup";
import { QUARTERS } from "Constants";

const CaseFindReport = () => {
  const rep_arr = [];
  for (let i = 0; i < 11; i++) {
    rep_arr.push(Array.apply(null, Array(24)).map(function () {}));
  }

  const [screeningArray, setScreeningArray] = useState(rep_arr);
  const [screeningPositiveArray, setScreeningPositiveArray] = useState(rep_arr);
  const [patientArray, setPatientArray] = useState(rep_arr);
  const [reportType, setReportType] = useState(0);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [result, setResult] = useState("");

  const handleRadioChange = (value) => {
    if (result === value) {
      setResult("");
    } else {
      setResult(value);
    }
  };

  const generate = (values) => {
    console.log("Success:", values);
    getScreeningAgeReport(values).then((out) => {
      setScreeningArray(out.data);
    });
    getScreeningAgeReportPositive(values).then((out) => {
      setScreeningPositiveArray(out.data);
    });
    getPatientAgeReport(values).then((out) => {
      setPatientArray(out.data);
    });
  };

  const FieldBody = React.forwardRef((props, ref) => {
    return (
      <>
        {" "}
        <Card>
          <div className="col-md-12" ref={ref}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                color="primary"
                onClick={handlePrint}
              >
                Print Report
              </Button>
            </div>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col>
                <img src={image1} alt="Image 1" style={{ height: 100 }} />
              </Col>
              <div style={{ textAlign: "center", marginRight: "10px" }}>
                <h5 style={{ margin: 0 }}>
                  National Programme for Tuberculosis Control and Chest Diseases
                </h5>
                <p style={{ margin: 0 }}>
                  Quarterly return on LTBI screening and case finding
                </p>
              </div>
              <Col>
                <img src={image2} alt="Image 2" style={{ height: 100 }} />
              </Col>
            </Row>
            <Card
              style={{
                margin: "25px 0",
                border: "2px solid black",
                padding: "20px",
              }}
            >
              <Form onFinish={generate}>
                <div className="row" style={{ width: "100%" }}>
                  <Col md={8}>
                    <div className="fos-label mt-2">District</div>
                    <div>
                      <FormSelect
                        className="mt-2"
                        // label="District"
                        name="district"
                        options={REPORT_DISTRICTS}
                      />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="fos-label mt-2">
                      Patients Registered During
                    </div>
                    <FormRadioGroup
                      label=""
                      name="period"
                      options={REPORT_TYPE}
                      setVal={(out) => setReportType(out)}
                    />
                  </Col>
                  <Col md={8}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="fos-label mt-2">
                          Patients Registered Year
                        </div>
                        <FormSelect
                          className="mt-2"
                          name="year"
                          options={[
                            ...Array(
                              new Date().getFullYear() - 1990 + 1
                            ).keys(),
                          ].map((year) => {
                            return { value: year + 1990, text: year + 1990 };
                          })}
                        />
                      </div>
                      {reportType == 0 ? (
                        <div className="col-md-6">
                          <div className="fos-label mt-2">
                            Patients Registered Quarter
                          </div>
                          <FormSelect
                            className="mt-2"
                            name="quarter"
                            options={QUARTERS}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </div>
                <div className="row" style={{ width: "100%" }}>
                  <Col md={8}>
                    <div className="fos-label mt-2">Name Of DTCO</div>
                    <div className="mt-2">
                      <Input placeholder="Enter Name Of DTCO" />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="fos-label mt-2">Signature</div>
                    <div className="mt-2">
                      <Input placeholder="Signature" />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="fos-label mt-2">&nbsp;</div>
                    <div className="mt-2">
                      <Button htmlType="submit">Generate</Button>
                    </div>
                  </Col>
                </div>
              </Form>
            </Card>

            <div style={{ margin: "10px 25px 20px 25px" }}>
              <Row>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <td rowspan={3} colspan={3}></td>
                      <td colspan={7}>Number of persons screened for LTBI</td>

                      <td colspan={7}>
                        Number of persons started on LTBI treatment
                      </td>
                      <td colspan={7}>Number of persons positive for LTBI</td>
                    </tr>
                    <tr>
                      <td colspan={2}>{"<5y"}</td>
                      <td colspan={2}>{"5<15y"}</td>
                      <td colspan={2}>{">=15y"}</td>
                      <td rowspan={2}>Total</td>
                      <td colspan={2}>{"<5y"}</td>
                      <td colspan={2}>{"5<15y"}</td>
                      <td colspan={2}>{">=15y"}</td>
                      <td rowspan={2}>Total</td>
                      <td colspan={2}>{"<5y"}</td>
                      <td colspan={2}>{"5<15y"}</td>
                      <td colspan={2}>{">=15y"}</td>
                      <td rowspan={2}>Total</td>
                    </tr>

                    <tr>
                      <td>M</td>
                      <td>F</td>
                      <td>M</td>
                      <td>F</td>
                      <td>M</td>
                      <td>F</td>
                      <td>M</td>
                      <td>F</td>
                      <td>M</td>
                      <td>F</td>
                      <td>M</td>
                      <td>F</td>
                      <td>M</td>
                      <td>F</td>
                      <td>M</td>
                      <td>F</td>
                      <td>M</td>
                      <td>F</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan={2}>HIV positive</td>
                      <td>PLHIV 1</td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[0].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[0].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[0].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                    <tr>
                      <td rowspan={9}>HIV Negative</td>
                      <td>Close contacts</td>
                      <td style={{ textAlign: "left" }}>
                        Close contact of <br />
                        TB patients 2
                      </td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[1].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[1].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[1].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                    <tr>
                      <td rowspan={6}>
                        Clinical risk <br />
                        groups
                      </td>
                      <td style={{ textAlign: "left" }}>
                        On treatement with <br />
                        anti-TNF alpha
                      </td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[2].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[2].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[2].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>
                        On treatement with non <br />
                        anti-TNF alpha biologics
                      </td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[3].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[3].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[3].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Receiving dialysis</td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[4].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[4].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[4].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>
                        Preparing for solid <br />
                        organ transplantation
                      </td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[5].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[5].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[5].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>
                        Preparing for Hematopoietic <br />
                        stem cell transplantation
                      </td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[6].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[6].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[6].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>With silicosis</td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[7].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[7].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[7].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                    <tr>
                      <td rowspan={2}>
                        Other vulnerable <br />
                        groups
                      </td>
                      <td style={{ textAlign: "left" }}>Healthcare workers</td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[8].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[8].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[8].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                    <tr>
                      {/* <td style={{ textAlign: "left" }}>Prison inmates</td>
                      {patientArray && patientArray.length > 0
                        ? patientArray[9].map((itm) => <td>{itm}</td>)
                        : null} */}
                      <td style={{ textAlign: "left" }}>Prison inmates</td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[8].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[8].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[8].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>

                    <tr>
                      <td colspan={2}>Other</td>
                      <td></td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[0].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[0].map((itm) => <td>{itm}</td>)
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[0].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                    <tr>
                      <td colspan={3}>Total</td>
                      {screeningArray && screeningArray.length > 0
                        ? screeningArray[10].map((itm) => <td>{itm}</td>)
                        : null}
                      {screeningPositiveArray &&
                      screeningPositiveArray.length > 0
                        ? screeningPositiveArray[10].map((itm) => (
                            <td>{itm}</td>
                          ))
                        : null}
                      {patientArray && patientArray.length > 0
                        ? patientArray[10].map((itm) => <td>{itm}</td>)
                        : null}
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </div>
            <div
              className="col-md-08"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
            <div style={{ margin: "10px 10px 50px 10px", textAlign: "center" }}>
              <p style={{ margin: 0 }}>
                1- For PLHIV age {"<5"} years, mention the total number as all
                are eligible for LTBI treatement
              </p>
              <p style={{ margin: 0 }}>
                2- For contacts age {"<5"} years, mention the total number as
                all are eligible for LTBI treatement
              </p>
            </div>
          </div>
        </Card>
      </>
    );
  });
  return (
    <FieldBody ref={componentRef} /> // Render the FieldBody component
  );
};
export default CaseFindReport;
