import { Button, Dropdown, Popconfirm, Space, Tooltip, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { setAlert, toggleSidebar } from "../../Reducers/Layout";
import SideBar from "./sidebar";
import { useEffect } from "react";

const AppHeader = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const collapsed = useSelector((state) => state.layout.collapsed);
  const page = useSelector((state) => state.layout.page);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!window.localStorage.getItem('token')) {
      window.location.href = "/login";
    }
  }, []);

  return (
    <Header style={{ padding: 0, background: colorBgContainer }}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => dispatch(toggleSidebar())}
            style={{ fontSize: "16px", width: 64, height: 64 }}
          />
        </div>
        <div className="fos-heading">{page}</div>
        <div className="d-flex">
          <div className="mt-1" style={{ paddingRight: 30 }}>
            <Space>
              Hi {window.localStorage.getItem('username')}
              <Popconfirm
                title="Logout"
                description="Are you sure you want to logout?"
                onConfirm={() => {
                  dispatch(setAlert({ type: 'success', message: 'Logout successfull' }));
                  setTimeout(() => {
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('username');
                    window.location.href = "/login"
                  }, 2000);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Logout">
                  <LogoutOutlined style={{ color: 'red' }} />
                </Tooltip>
              </Popconfirm>
            </Space>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
