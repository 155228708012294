import {
  DesktopOutlined,
  PieChartOutlined,
  DiffOutlined,
  FileDoneOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import Home from "./Components/Pages/Home";
import ScreeningRecords from "./Pages/ScreeningRecords";
import Login from "./Pages/Login";
import ScreeningRegister from "./Pages/ScreeningRegister";
import Reports from "Pages/Reports";
import PatientManager from "Pages/PatientManager";
import Users from "Pages/Users";

export const hasPermission = (permissions = []) => {
  const perm = window.localStorage.getItem("permissions");
  if(!perm) {
    return true;
  }
  const avail = permissions?.some(r => (perm.toString().split(',')).includes(r.toString()));
  if (avail) {
    return true;
  } else {
    return false;
  }
}

function getItem(
  label,
  key,
  icon,
  children,
  url = null,
  element = null,
  permissions = [],
  auth = true,
  nav = true,
) {
  console.log(permissions);
  if (permissions.length == 0) {
    return {
      key,
      icon,
      children,
      label,
      url,
      element,
      auth,
      nav,
    };
  }
  if (hasPermission(permissions)) {
    return {
      key,
      icon,
      children,
      label,
      url,
      element,
      auth,
      nav,
    };
  } else {
    return null;
  }
}

export const items = [
  getItem("Dashboard", "1", <PieChartOutlined />, null, "/", <Home />, [1]),
  getItem(
    "Screening",
    "2",
    <FileDoneOutlined />,
    null,
    "/screening",
    <ScreeningRecords />,
    [2, 3, 4, 5]
  ),
  getItem(
    "Screening Register",
    "2",
    <DiffOutlined />,
    null,
    "/screening_register",
    <ScreeningRegister />,
    [2, 3, 4, 5]
  ),
  getItem("Reports", "2", <DesktopOutlined />, null, "/reports", <Reports />, [10, 11, 12, 13, 14, 15, 16, 17]),
  getItem(
    "Patients",
    "3",
    <PieChartOutlined />,
    null,
    "/patients",
    <PatientManager />,
    [6, 7, 8, 9]
  ),
  getItem("Users", "6", <GroupOutlined />, null, "/users", <Users />, [18, 19, 20, 21]),
  getItem(
    "Login",
    "18",
    <PieChartOutlined />,
    null,
    "/login",
    <Login />,
    [],
    false,
    false
  ),
  // getItem("User Levels", "7", <GroupOutlined />, null, "/users", <Users />),
];

export const DISTRICTS = [
  { value: 1, text: "Colombo" },
  { value: 2, text: "Gampaha" },
  { value: 3, text: "Kalutara" },
  { value: 4, text: "Kandy" },
  { value: 5, text: "Matale" },
  { value: 6, text: "Nuwara Eliya" },
  { value: 7, text: "Galle" },
  { value: 8, text: "Matara" },
  { value: 9, text: "Hambantota" },
  { value: 10, text: "Jaffna" },
  { value: 11, text: "Kilinochchi" },
  { value: 12, text: "Mannar" },
  { value: 13, text: "Vavuniya" },
  { value: 14, text: "Mullaitivu" },
  { value: 15, text: "Batticaloa" },
  { value: 16, text: "Ampara" },
  { value: 17, text: "Trincomalee" },
  { value: 18, text: "Kurunegala" },
  { value: 19, text: "Puttalam" },
  { value: 20, text: "Anuradhapura" },
  { value: 21, text: "Polonnaruwa" },
  { value: 22, text: "Badulla" },
  { value: 23, text: "Moneragala" },
  { value: 24, text: "Ratnapura" },
  { value: 25, text: "Kegalle" },
  { value: 26, text: "Kalmune" },
];

export const GENDER = [
  { value: 1, text: "Male" },
  { value: 0, text: "Female" },
];
export const SCREENINGS = [
  { value: 0, text: "General" },
  { value: 1, text: "Investigations" },
  { value: 2, text: "Outcome" },
];
export const SYMPTOMS = [
  { value: "Cough", text: "Cough" },
  {
    value: "Shortness of breath",
    text: "Shortness of breath",
  },
  {
    value: "Chest Pain",
    text: "Chest Pain",
  },
  {
    value: "Hemoptysis",
    text: "Hemoptysis",
  },
  {
    value: "Low grade fever",
    text: "Low grade fever",
  },
  {
    value: "Night Sweats",
    text: "Night Sweats",
  },
  {
    value: "Loss of Appetite",
    text: "Loss of Appetite",
  },
  {
    value: "Loss of Weight",
    text: "Loss of Weight",
  },
  {
    value: "Fatigability",
    text: "Fatigability",
  },
  {
    value: "Other",
    text: "Other",
  },
];

export const CATEGORIES = [
  { value: 1, text: "PLHIV" },
  { value: 2, text: "Close contacts" },
  { value: 3, text: "Clinical risk groups" },
  { value: 4, text: "Other vulnerable groups" },
  { value: 5, text: "Other" },
];

export const CLOSE_CATEGORY = [
  { value: 21, text: "Close contact of TB patient - Age under 5 years" },
  { value: 22, text: "Close contact of TB patient - Age 5-14 years" },
  { value: 23, text: "Close contact of TB patient - Age 15 years and Above" },
];

export const CLINICAL_RISK = [
  { value: 31, text: "Patient on treatment with anti-TNF alpha" },
  { value: 32, text: "Patient on treatment with non-anti-TNF alpha biologics" },
  { value: 33, text: "Patient receiving dialysis" },
  { value: 34, text: "Patient preparing for solid organ transplatation" },
  {
    value: 35,
    text: "Patient preparing for Hematopoietic stem cell transplatation",
  },
  { value: 36, text: "Patient with silicosis" },
];

export const VULNERABLE = [
  { value: 41, text: "Healthcare workers" },
  { value: 42, text: "Prison inmates" },
];

export const INVESTIGATIONS = [
  { value: "TST", text: "TST" },
  { value: "IGRA", text: "IGRA" },
  { value: "CXR", text: "Chest X-Ray" },
  { value: "SMEAR", text: "SMEAR" },
  { value: "XPERT", text: "XPERT" },
  { value: "OTHER", text: "OTHER" },
];

export const CXR = [
  { value: 1, text: "TB Compatible" },
  { value: 2, text: "Equivocal" },
  { value: 3, text: "Clear" },
];

export const YESNO = [
  { value: 0, text: "No" },
  { value: 1, text: "Yes" },
];

export const PRESENTPAST = [
  { value: 0, text: "Present" },
  { value: 1, text: "Past" },
  { value: 2, text: "Never" },
];

export const REGIME = [
  { value: "3HP", text: "3HP" },
  { value: "1HP", text: "1HP" },
  { value: "3HR", text: "3HR" },
  { value: "6H", text: "6H" },
  { value: "9H", text: "9H" },
  { value: "36H", text: "36H" },
  { value: "3-4HR", text: "3-4HR" },
];

export const FREQUENCY = [
  { value: "Weekly", text: "Weekly" },
  { value: "Daily", text: "Daily" },
];

export const DURATION = [
  { value: "12 Weeks", text: "12 Weeks" },
  { value: "One Month", text: "One Month" },
  { value: "3 Months", text: "3 Months" },
  { value: "6 Months", text: "6 Months" },
  { value: "9 Months", text: "9 Months" },
  { value: "36 Months", text: "36 Months" },
  { value: "3-4 Months", text: "3-4 Months" },
];

export const OUTCOME = [
  { value: 0, text: "Treatment ongoing" },
  { value: 1, text: "Treatment completed" },
  { value: 2, text: "Treatment failed" },
  { value: 3, text: "Died" },
  { value: 4, text: "Lost to follow up" },
  { value: 5, text: "Treatment Discontinued due to clinical reasons" },
  //   { value: 5, text: "TPT discontinuation due to toxicity" },
  { value: 6, text: "Not Evaluated" },
];

export const REPORT_DISTRICTS = [
  { value: 1, text: "All" },
  { value: 2, text: "Colombo" },
  { value: 3, text: "Gampaha" },
  { value: 4, text: "Kalutara" },
  { value: 5, text: "Kandy" },
  { value: 6, text: "Matale" },
  { value: 7, text: "Nuwara Eliya" },
  { value: 8, text: "Galle" },
  { value: 9, text: "Matara" },
  { value: 10, text: "Hambantota" },
  { value: 11, text: "Jaffna" },
  { value: 12, text: "Kilinochchi" },
  { value: 13, text: "Mannar" },
  { value: 14, text: "Vavuniya" },
  { value: 15, text: "Mullaitivu" },
  { value: 16, text: "Batticaloa" },
  { value: 17, text: "Ampara" },
  { value: 18, text: "Trincomalee" },
  { value: 19, text: "Kurunegala" },
  { value: 20, text: "Puttalam" },
  { value: 21, text: "Anuradhapura" },
  { value: 22, text: "Polonnaruwa" },
  { value: 23, text: "Badulla" },
  { value: 24, text: "Moneragala" },
  { value: 25, text: "Ratnapura" },
  { value: 26, text: "Kegalle" },
  { value: 27, text: "Kalmune" },
];

export const REPORT_TYPE = [
  { value: 0, text: "Quarter" },
  { value: 1, text: "Annual" },
];

export const QUARTERS = [
  { value: 1, text: "Q1" },
  { value: 2, text: "Q2" },
  { value: 3, text: "Q3" },
  { value: 4, text: "Q4" },
];
