import { Button, Card, Form } from "antd";
import FormInput from "Components/form/FormInput";

import FormRadioGroup from "Components/form/FormRadioGroup";
import { GENDER } from "Constants";

import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";

import dayjs from "dayjs";

import { useReactToPrint } from "react-to-print";
import { DISTRICTS } from "Constants";
import FormSelect from "Components/form/FormSelect";

const TreatmentCard = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [presumptive, setPresumptive] = useState(0);
  const [ageYears, setAgeYears] = useState(0);
  const [ageMonths, setAgeMonths] = useState(0);
  const [isPrint, setIsPrint] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // onBeforeGetContent: () => {
    //   setTimeout(() => {
    //     setIsPrint(true);
    //   }, 1000);
    // },
    // onAfterPrint: () => setIsPrint(false),
  });

  const onFinish = (values) => {
    console.log("Success:", values);
    if (selected) {
    } else {
    }
  };
  const calcAge = (selectedDate) => {
    const currentDate = new Date();

    const yearsDiff = currentDate.getFullYear() - selectedDate.getFullYear();
    const monthsDiff = currentDate.getMonth() - selectedDate.getMonth();
    const daysDiff = currentDate.getDate() - selectedDate.getDate();

    let calculatedAgeYears = yearsDiff;
    let calculatedAgeMonths = monthsDiff;

    if (daysDiff < 0) {
      calculatedAgeMonths -= 1;
      if (calculatedAgeMonths < 0) {
        calculatedAgeYears -= 1;
        calculatedAgeMonths += 12;
      }
    }

    setAgeYears(calculatedAgeYears);
    setAgeMonths(calculatedAgeMonths);

    form.setFieldValue("age", `${calculatedAgeYears}/${calculatedAgeMonths}`);
  };

  const handleInputChange = (ctx) => {
    //   console.log(new Date(ctx?.$d));
    // console.log(`${ctx.$y}-${ctx.$m}-${ctx.$D}`);

    ctx.$d && calcAge(ctx.$d);

    // const { name, value } = e.target;
    // console.log(value);
    // if (name === "dob") {
    //   setBirthDate(value);

    // }
  };

  useEffect(() => {
    if (selected) {
      console.log("SELECTED", selected);
      Object.entries(selected).forEach(([key, value]) => {
        form.setFieldValue(key, key == "dob" ? dayjs(value) : value);
      });
      setPresumptive(selected["presumptive_category"]);
    } else {
      form.resetFields();
    }
  }, [selected]);

  useEffect(() => {
    form.setFieldValue("presumptive_category_sub", null);
  }, [presumptive]);

  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
        <div className="mt-4">
          <Button
            style={{ width: "max-content" }}
            className="fos-btn-primary"
            type="success"
            // color="primary"
            onClick={() => {
              // setIsPrint(true);
              handlePrint();
            }}
            title="Print certificate"
          >
            Print Treatment Card
          </Button>
        </div>
      </div>
      <div
        className="d-flex justify-content-center"
        ref={componentRef}
        // style={{ padding: "0", margin: "0" }}
      >
        <Form onFinish={onFinish} form={form} layout="vertical">
          <Card
            headStyle={{ textAlign: "center" }}
            title=" LTBI Treatment Card"
            className="p-2"
          >
            <div className="d-flex justify-content-between">
              <div className="col-md-5">
                <FormSelect
                  label="District"
                  name="district"
                  options={DISTRICTS}
                />
              </div>
              <div className="col-md-6">
                <FormInput
                  label="Chest Clinic Contact No."
                  name="chest_clinic_contact_no"
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-md-5">
                <FormInput label="Full Name" name="name" />
              </div>
              <div className="col-md-6">
                <FormInput label="District LTBI No." name="ltbi_no" />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              {/* <div className="col-md-4">
                <FormInput
                  label="Date of birth"
                  type="date"
                  name="dob"
                  onChange={handleInputChange}
                />
              </div> */}
              <div className="col-md-4">
                <FormInput
                  label="Age (years/months)"
                  type="number"
                  name="age"
                  disabled={true}
                />
              </div>
              <div className="col-md-4">
                <FormRadioGroup label="Sex" name="sex" options={GENDER} />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-md-6">
                <FormInput label="Complete Address" name="permanent_address" />
              </div>
              <div className="col-md-6">
                <FormInput label="Mobile No." name="mobile_phone" />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-md-4">
                <FormInput label="Date" name="date" type="date" />
              </div>
              <div className="col-md-4">
                <FormInput label="Investigations" name="investigations" />
              </div>
              <div className="col-md-4">
                <FormInput label="Results" name="results" />
              </div>
            </div>
            <div className="d-flex justify-content-evenly">
              <div className="col-md-5">
                <FormInput
                  label="Date Treatment Started"
                  name="date_treatment_started"
                  type="date"
                />
              </div>
              <div className="col-md-5">
                <FormInput
                  label="Date Treatment Completed"
                  name="date_treatment_completed"
                  type="date"
                />
              </div>
            </div>

            <div className="d-flex justify-content-evenly">
              <div className="col-md-5">
                <FormInput label="Treatment Regimen" name="treatment_regimen" />
              </div>
              <div className="col-md-5">
                <FormInput label="Treatment Outcome" name="treatment_outcome" />
              </div>
            </div>
            {!isPrint && (
              <div className="d-flex justify-content-center">
                <Form.Item>
                  {/* <Button type="primary" htmlType="submit">
                    Add
                  </Button> */}
                </Form.Item>
              </div>
            )}
          </Card>
        </Form>
      </div>
    </>
  );
};

export default TreatmentCard;
