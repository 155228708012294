import { Table, Button, Modal, Card, Input, Form, Steps, Popconfirm } from "antd";
import FormInput from "Components/form/FormInput";
import FormRadioGroup from "Components/form/FormRadioGroup";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { YESNO } from "Constants";
import { addOutcome } from "Api";
import { setSelected } from "Reducers/Screening";
import { setAlert } from "Reducers/Layout";
import { getScreeningItem } from "Api";

const Outcome = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.screening.selected);
  const [tb, setTb] = useState(0);
  const [ltbi, setLtbi] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     console.log({ tb, ltbi });
  //   }, [tb, ltbi]);

  const onFinish = (values) => {
    console.log("Success:", values);
    if (selected) {
      values.screening_no = selected.screening_no;
      setLoading(true);
      addOutcome(values).then((out) => {
        console.log("Screening Out", out);
        if (out.status == 200 || out.status == 201) {
          dispatch(setAlert({ type: 'success', message: 'Outcome updated' }));
          getScreeningItem(selected.screening_no)
            .then(res => {
              form.resetFields();
              if (res.data && res.data[0]) {
                dispatch(setSelected(res.data[0]));
              }
              setLoading(false);
            })
            .catch(e => {
              dispatch(setAlert({ type: 'error', message: 'Error fetching record' }));
              setLoading(false);
            })
        } else {
          dispatch(setAlert({ type: 'error', message: out.data?.message }));
          setLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (selected?.outputs?.at(0)) {
      const object = selected?.outputs?.at(0);
      Object.entries(object).forEach(([key, value]) => {
        console.log({ key, value });
        form.setFieldValue(key, key === "dob" ? dayjs(value) : value);
      });
    } else {
      form.resetFields();
    }
  }, [selected]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-3">
            <FormRadioGroup
              label="Active TB"
              name="tb"
              options={YESNO}
              setVal={(out) => setTb(out)}
            />
          </div>
          {!tb ? (
            <>
              <div className="col-md-3">
                <FormRadioGroup
                  label="LTBI"
                  name="ltbi"
                  options={YESNO}
                  setVal={(out) => setLtbi(out)}
                />
              </div>
              {ltbi ? (
                <div className="col-md-3">
                  <FormRadioGroup
                    label="Treatment"
                    name="treatment"
                    options={YESNO}
                  />
                </div>
              ) : null}
              <div className="col-md-3">
                <FormRadioGroup
                  label="Need Treatment"
                  name="needTreatment"
                  options={YESNO}
                />
              </div>
            </>
          ) : null}
          <div className="col-md-3">
            <FormInput label="Comment" name="comment" type="textarea" />
          </div>
          {(!selected.outcome) ? <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'end' }}>
            <Popconfirm
              title="Update outcome"
              description={`Are you sure to update outcome?`}
              onConfirm={() => form.submit()}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" htmlType="button" loading={loading}>
                Update Outcome
              </Button>

            </Popconfirm>
          </Form.Item> : null}
        </div>
      </Card>
    </Form>
  );
};

export default Outcome;
