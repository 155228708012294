import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Table from "react-bootstrap/Table";
import { Button, Card, Col, Row } from "antd";
import { getScreeningReport } from "Api";

import image1 from "../../../Assests/logo_lk.png";
import image2 from "../../../Assests/LTBI.png";

const ScreeningRegReport = () => {
  const [rows, setRows] = useState([]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getScreeningReport().then((out) => {
      console.log("SCREENING REP", out);
      setRows([...out.data]);
    });
  }, []);

  const FieldBody = React.forwardRef((props, ref) => {
    return (
      <>
        {" "}
        <Card>
          <div className="col-md-12" ref={ref}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                color="danger"
                onClick={handlePrint}
              >
                Print Report
              </Button>
            </div>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col>
                <img src={image1} alt="Image 1" style={{ height: 100 }} />
              </Col>
              <div style={{ textAlign: "center", marginRight: "10px" }}>
                <h5 style={{ margin: 0 }}>
                  National Programme for Tuberculosis Control and Chest Diseases
                </h5>
                <p style={{ margin: 0 }}>District LTBI Screening Register</p>
              </div>
              <Col>
                <img src={image2} alt="Image 2" style={{ height: 100 }} />
              </Col>
            </Row>
            {/* <div style={{ textAlign: "center" }}>
              <h5 style={{ margin: 0 }}>
                National Programme for Tuberculosis Control and Chest Diseases
              </h5>
              <p style={{ margin: 0 }}>District LTBI Screening Register</p>
            </div> */}
            <div
              className="col-md-08"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
            <div style={{ margin: "10px 10px 10px 25px" }}>
              <Row>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <td rowSpan={2}>Date of screening</td>
                      <td rowSpan={2}>LTBI Screening No/ Standard Card No</td>
                      <td rowSpan={2}>Full name</td>
                      <td rowSpan={2}>Complete address</td>
                      <td rowSpan={2}>Age</td>
                      <td rowSpan={2}>Sex</td>
                      <td colSpan={5}>Latent TB screening category</td>
                      <td colSpan={4}>Investigations for active TB</td>
                      <td rowSpan={2}>Active TB screening outcome4</td>
                      <td colSpan={2}>Investigations for latent TB</td>
                      <td rowSpan={2}>Latent TB screening outcome5</td>
                      <td rowSpan={2}>Remarks 6</td>
                    </tr>
                    <tr>
                      <td>PLHIV</td>
                      <td>Close contact 1</td>
                      <td>If close contact, DTB no of index patient</td>
                      <td>Clinical risk group 2</td>
                      <td>Other vulnerable group 3</td>
                      <td>Smear</td>
                      <td>CXR</td>
                      <td>Xpert MTB-RIF</td>
                      <td>Other (specify)</td>
                      <td>TST</td>
                      <td>IGRA</td>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row) => {
                      return (
                        <tr>
                          <td>{row.date}</td>
                          <td>{row.screening_no}</td>
                          <td>{row.name}</td>
                          <td>{row.address}</td>
                          <td>{row.age}</td>
                          <td>{row.sex}</td>
                          <td>{row.plhiv}</td>
                          <td>{row.close}</td>
                          <td>{row.index_patient}</td>
                          <td>{row.clinical}</td>
                          <td>{row.vulnerable}</td>
                          <td>{row.sputum}</td>
                          <td>{row.cxr}</td>
                          <td>{row.xpert}</td>
                          <td>{row.other_reason}</td>
                          <td>{row.tb}</td>
                          <td>{row.tst}</td>
                          <td>{row.igra}</td>
                          <td>{row.ltbi}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            </div>

            <div
              className="col-md-08"
              sm={8}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
            <div style={{ margin: "10px 10px 50px 10px", textAlign: "center" }}>
              <p style={{ margin: 0 }}>
                1: Enter "H" for household close contacts and "NH" for
                non-household close contacts
              </p>
              <p style={{ margin: 0 }}>
                2: Enter "TNF for patients on treatment with anti-TNF alpha,
                "NINF" for patients on treatment with non-anti-TNF alpha, "HD"
                for patients receiving dialysis, "SOT" for patients preparing
                for solid organ transplantation, "HSCT" for patient preparing
                for hematopoietic stem cell transplantation, "SIL" for patients
                with sibicosis
              </p>
              <p style={{ margin: 0 }}>
                3: Enter "H" for health care workers and “P” for prisoners
              </p>
              <p style={{ margin: 0 }}>
                4. Enter "P" for positive and "N" for negative
              </p>
              <p style={{ margin: 0 }}>
                5. Enter "P" for positive and "N" for negative
              </p>
              <p style={{ margin: 0 }}>
                6. Enter DTB no where TB treatment started and DLTBI no where
                LFBI treatment started
              </p>
            </div>
          </div>
        </Card>
      </>
    );
  });
  return (
    <FieldBody ref={componentRef} handlePrint={handlePrint} /> // Render the FieldBody component
  );
};

export default ScreeningRegReport;
