import { Form, Input, Tag } from "antd";
import { CheckOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";

const FormMulti = ({ label = "", name = "", options = [], setVal = (param, val) => { }, defaults = [] }) => {
    const [arr, setArr] = useState(defaults);
    return <Form.Item label={label} name={name}>
        <div>
            {options.map((opt) => {
                const included = arr.includes(opt.value);
                return <Tag size="md" m='5px' color={included ? 'teal' : ''} cursor='pointer' onClick={() => {
                    if (!included) {
                        setArr([...arr, opt.value]); setVal(name, ([...arr, opt.value]).toString());
                    } else {
                        const tmp = arr.filter((itm) => itm !== opt.value);
                        setArr(tmp); setVal(name, tmp.toString());
                    }
                }}>
                    {included ? <CheckOutlined /> : null}
                    {opt.text}
                </Tag>
            })}
        </div>
        <Input type="hidden" value={arr.toString()} />
    </Form.Item>
}

export default FormMulti;