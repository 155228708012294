import React, { useEffect, useState } from "react";
import { Space, Table, Tag } from "antd";
import { Card } from "antd";
import TotalDonationThisYear from "../modules/BreakdownOfCategories/closecontact";
import TotalDonationThisMonth from "../modules/TotalDonationThisMonth";
import RegisteredChildrenThisYear from "../modules/RegisteredChildrenThisYear";
import RegisteredChildrenThisMonth from "../modules/RegisteredChildrenThisMonth";
import Donation from "../charts/Donation";
import TotalDonation from "../charts/TotalDonations";
import NewRegistrations from "../charts/NewRegistrations";
import Target from "../charts/Target";
import CloseContact from "../modules/BreakdownOfCategories/closecontact";
import PLHIV from "Components/modules/BreakdownOfCategories/PLHIV";
import OtherVulnerableGroup from "Components/modules/BreakdownOfCategories/OtherVulnerableGroup";
import ClinicalRiskGroup from "Components/modules/BreakdownOfCategories/ClinicalRiskGroup";
import ActiveDisease from "Components/modules/NoOfScreened/ActiveDisease";
import NoOfPositive from "Components/modules/NoOfScreened/NoOfPositive";
import NoOfPositive2 from "Components/modules/NoOfScreened/NoOfPositive2";
import Infection from "Components/modules/NoOfScreened/Infection";
import PreventiveTreatment from "Components/modules/NoOfStartedCompleted/PreventiveTreatment";
import CompletedPreventiveTreatment from "Components/modules/NoOfStartedCompleted/CompletedPreventiveTreatment";
import BelowAgeFive from "Components/modules/AgeCategories/BelowAgeFive";
import AgeFiftyFiveAbove from "Components/modules/AgeCategories/AgeFiftyFiveAbove";
import AgeFivetoFourteen from "Components/modules/AgeCategories/AgeFivetoFourteen";
import AgeFifteentoFiftyFive from "Components/modules/AgeCategories/AgeFifteentoFiftyFive";
import ReactECharts from "echarts-for-react";
import { getDashboard } from "Api";
import DashboardCard from "Components/modules/DashboardCard";

function Home() {
  const [dashboard, setDashboard] = useState([]);
  const year = new Date().getFullYear();
  const handleButtonClick = (category) => {
    // Handle the button click for the specific category
    console.log(`Button clicked for ${category}`);
    // You can add your logic or state updates here
  };

  useEffect(() => {
    getDashboard().then((out) => {
      if (out.data) {
        setDashboard(out?.data);
      }
    })
  }, []);

  const options1 = {
    legend: {},
    tooltip: {},
    dataset: {
      source: dashboard && dashboard.length > 0 ? dashboard[0] : []
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
  };

  const options2 = {
    legend: {},
    tooltip: {},
    dataset: {
      source: dashboard && dashboard.length > 0 ? dashboard[1] : []
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: 'bar' }, { type: 'bar' }]
  };

  const options3 = {
    legend: {},
    tooltip: {},
    dataset: {
      source: dashboard && dashboard.length > 0 ? dashboard[2] : []
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
  };
  const options4 = {
    legend: {},
    tooltip: {},
    dataset: {
      source: dashboard && dashboard.length > 0 ? dashboard[3] : []
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: 'bar' }, { type: 'bar' }]
  };
  return (
    <>
      <div>
        <div className="fos-heading">LTBI dashboard</div>
      </div>
      {/* <div className="row mt-3">
        <div className="col-md-3">
          <DashboardCard val={dashboard[0]?.len} label="Close contact" />
        </div>
        <div className="col-md-3">
          <DashboardCard val={dashboard[1]?.len} label="Clinical Risk Group" />
        </div>
        <div className="col-md-3">
          <DashboardCard
            val={dashboard[2]?.len}
            label="Other Vulnerable Group"
          />
        </div>
        <div className="col-md-3">
          <DashboardCard val={dashboard[3]?.len} label="PLHIV" />
        </div>
      </div>

      <div>
        <div className="fos-heading-small">
          No screened for active disease & no. Positive / No screened for
          infection and no. Positive
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <DashboardCard
            val={dashboard[4]?.len}
            label="No Screened for active disease"
          />
        </div>
        <div className="col-md-3">
          <DashboardCard
            val={dashboard[5]?.len}
            label="No of positive for active disease"
          />
        </div>
        <div className="col-md-3">
          <DashboardCard
            val={dashboard[6]?.len}
            label="No Screened for infection"
          />
        </div>
        <div className="col-md-3">
          <DashboardCard
            val={dashboard[7]?.len}
            label="No of positive for infection No with LTBI"
          />
        </div>
      </div>

      <div>
        <div className="fos-heading-small">
          Disaggregated by age categories - LTBI
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <DashboardCard val={dashboard[8]?.len} label="Below Age Five" />
        </div>
        <div className="col-md-3">
          <DashboardCard val={dashboard[9]?.len} label="Age Five to Fourteen" />
        </div>
        <div className="col-md-3">
          <DashboardCard
            val={dashboard[10]?.len}
            label="Age Fifteen to Fifty Five"
          />
        </div>
        <div className="col-md-3">
          <DashboardCard
            val={dashboard[11]?.len}
            label="Age fifty Five And Above"
          />
        </div>
      </div>
      <div>
        <div className="fos-heading-small">
          No started on preventive treatment / No. Completed preventive
          treatment
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <DashboardCard
            val={dashboard[12]?.len}
            label="started preventive treatment"
          />
        </div>
        <div className="col-md-3">
          <DashboardCard
            val={dashboard[13]?.len}
            label="Completed preventive treatment"
          />
        </div>
      </div> */}

      {/* charts */}

      <div className="row mt-4">
        <div className="col-md-6">
          <Card>
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold mini-header">
                Number of individuals screened, positive and started on TPT by risk category : {year}
              </div>
            </div>
            <div className="mt-3 w-100">
              <ReactECharts option={options1} />
            </div>
          </Card>
        </div>
        <div className="col-md-6">
          <Card>
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold mini-header">
                Number of individuals started on TPT and number completed treatment : {year - 1}
              </div>
            </div>
            <div className="mt-3 w-100">
              <ReactECharts option={options2} />
            </div>
          </Card>
        </div>
        <div className="col-md-6 mt-4">
          {" "}
          <Card>
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold mini-header">
                Disaggregation by age categories for TPT started : {year}
              </div>
            </div>
            <div className="mt-3 w-100">
              <ReactECharts option={options3} />
            </div>
          </Card>
        </div>
        <div className="col-md-6 mt-4">
          <Card>
            <div className="d-flex justify-content-between">
              <div className="fos-card-heading font-bold mini-header">
                Disaggregation by gender for TPT started : {year}
              </div>
            </div>
            <div className="mt-3 w-100">
              <ReactECharts option={options4} />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
export default Home;
