import { Table, Button, Modal, Card, Input, Form, Popconfirm } from "antd";
import FormInput from "Components/form/FormInput";
import { DISTRICTS } from "Constants";
import FormSelect from "Components/form/FormSelect";
import FormRadioGroup from "Components/form/FormRadioGroup";
import { GENDER } from "Constants";
import FormMulti from "Components/form/FormMulti";
import { CATEGORIES } from "Constants";
import { SYMPTOMS } from "Constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { createScreening, getScreeningItem } from "Api";
import dayjs from "dayjs";
import { CLOSE_CATEGORY, CLINICAL_RISK, VULNERABLE } from "Constants";
import { setAlert } from "Reducers/Layout";
import Joi from "joi";
import { setSelected } from "Reducers/Screening";

const General = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.screening.selected);
  const [presumptive, setPresumptive] = useState(0);
  const [symptoms, setSymptoms] = useState([]);
  const [ageYears, setAgeYears] = useState(0);
  const [ageMonths, setAgeMonths] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Calculate age function
  const calcAge = (selectedDate) => {
    const currentDate = new Date();

    const yearsDiff = currentDate.getFullYear() - selectedDate.getFullYear();
    const monthsDiff = currentDate.getMonth() - selectedDate.getMonth();
    const daysDiff = currentDate.getDate() - selectedDate.getDate();

    let calculatedAgeYears = yearsDiff;
    let calculatedAgeMonths = monthsDiff;

    if (daysDiff < 0) {
      calculatedAgeMonths -= 1;
      if (calculatedAgeMonths < 0) {
        calculatedAgeYears -= 1;
        calculatedAgeMonths += 12;
      }
    }

    setAgeYears(calculatedAgeYears);
    setAgeMonths(calculatedAgeMonths);

    form.setFieldValue("age", `${calculatedAgeYears}/${calculatedAgeMonths}`);
  };

  const validator = async (data) => {
    const joi = Joi.object({
      standard_card_no: Joi.string().required(),
      pin: Joi.string().required(),
      district: Joi.number().required(),
      name: Joi.string().required(),
      sex: Joi.number().required(),
      dob: Joi.date().required(),
      age: Joi.string().required(),
      past_tb: Joi.string(),
      mobile_phone: Joi.number(),
      home_phone: Joi.number(),
      guardian_phone: Joi.number(),
      permanent_address: Joi.string(),
      residential_address: Joi.string(),
      presumptive_category: Joi.number().required(),
      presumptive_category_sub: Joi.number(),
      screening_no: Joi.number(),
      symptoms: Joi.string(),
      tb_no: Joi.string(),
    });
    if (!joi) {
      return "Validator not found";
    }

    const val = joi.validate(data);
    if (val.error) {
      return val.error.details.map((itm) => itm.message).toString();
    }
    return null;
  };

  // Submit function
  const onFinish = async (values) => {
    try {
      values.dob = values.dob ? values.dob.$d : null;
      console.log(values);
      const isInvalid = await validator(values);
      if (isInvalid) {
        dispatch(setAlert({ type: "error", message: isInvalid }));
        return;
      }
      setLoading(true);
      if (selected) {
        // handle updates
      } else {
        createScreening(values)
          .then((out) => {
            console.log("Screening Out", out);
            if (out.status == 200 || out.status == 201) {
              dispatch(
                setAlert({
                  type: "success",
                  message: "Screening record created",
                })
              );
              getScreeningItem(out.data?.screening_id)
                .then((res) => {
                  form.resetFields();
                  if (res.data && res.data[0]) {
                    dispatch(setSelected(res.data[0]));
                  }
                  setLoading(false);
                })
                .catch((e) => {
                  dispatch(
                    setAlert({
                      type: "error",
                      message: "Error fetching record",
                    })
                  );
                  setLoading(false);
                });
            } else {
              dispatch(setAlert({ type: "error", message: out.data?.message }));
              setLoading(false);
            }
          })
          .catch((e) => {
            setLoading(false);
            dispatch(setAlert({ type: "error", message: "Unexpected Error" }));
          });
      }
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
      dispatch(
        setAlert({ type: "error", message: "Resolve validation errors" })
      );
    }
  };

  // Handle date input change and calculate age
  const handleInputChange = (ctx) => {
    ctx && ctx.$d && calcAge(ctx.$d);
  };

  // useEffect to handle selected patient data and calculate age
  useEffect(() => {
    if (selected) {
      Object.entries(selected).forEach(([key, value]) => {
        form.setFieldValue(key, key === "dob" ? dayjs(value) : value);
      });
      setPresumptive(selected["presumptive_category"]);

      // Calculate age after setting dob
      if (selected.dob) {
        calcAge(new Date(selected.dob));
      }

      // For symptoms as it is an array object
      const symptoms = selected.symptoms;
      symptoms?.forEach((s) => form.setFieldValue("symptoms", s.symptom));
    } else {
      form.resetFields();
    }
  }, [selected]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          {/* Form fields */}
          <div className="col-md-3">
            <FormInput
              label="District LTBI Screening Number"
              name="screening_no"
              disabled
            />
          </div>
          <div className="col-md-3">
            <FormInput label="Standard Card Number" name="standard_card_no" />
          </div>
          <div className="col-md-3">
            <FormInput label="Patient identification number" name="pin" />
          </div>
          <div className="col-md-3">
            <FormSelect label="District" name="district" options={DISTRICTS} />
          </div>
          <div className="col-md-3">
            <FormInput label="Name" name="name" required />
          </div>
          <div className="col-md-3">
            <FormRadioGroup label="Sex" name="sex" options={GENDER} />
          </div>
          <div className="col-md-3">
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <FormInput
                label="Date of birth"
                type="date"
                name="dob"
                onChange={handleInputChange}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormInput
                label="Age (years/months)"
                name="age"
                disabled={true}
              />
            </div>
          </div>
          <div className="col-md-3">
            <FormInput label="Past TB History" name="past_tb" />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Mobile"
              name="mobile_phone"
              type="number"
              extra={{ maxLength: 10 }}
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Home phone number"
              name="home_phone"
              type="number"
              extra={{ maxLength: 10 }}
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Guardian phone number"
              name="guardian_phone"
              type="number"
              extra={{ maxLength: 10 }}
            />
          </div>
          <div className="col-md-6">
            <FormInput label="Permanent address" name="permanent_address" />
          </div>
          <div className="col-md-6">
            <FormInput label="Residential address" name="residential_address" />
          </div>
          <div className="col-md-3">
            <FormSelect
              label="Presumptive LTBI Category"
              name="presumptive_category"
              setVal={(val) => {
                setPresumptive(val);
              }}
              options={CATEGORIES}
            />
          </div>
          {presumptive != 1 && presumptive != 5 ? (
            <div className="col-md-3">
              <FormSelect
                label="Presumptive LTBI Category"
                name="presumptive_category_sub"
                options={
                  presumptive == 2
                    ? CLOSE_CATEGORY
                    : presumptive == 3
                    ? CLINICAL_RISK
                    : VULNERABLE
                }
              />
            </div>
          ) : null}
          {presumptive == 2 ? (
            <div className="col-md-3">
              <FormInput label="DTB No. of TB patient" name="tb_no" required />
            </div>
          ) : null}
          {presumptive == 5 ? (
            <div className="col-md-3">
              <FormInput label="Other reason" name="other_reason" required />
            </div>
          ) : null}
          <div className="col-md-12">
            <FormMulti
              label="Symptoms"
              name="symptoms"
              options={SYMPTOMS}
              setVal={(key, val) => {
                form.setFieldValue(key, val);
              }}
              defaults={
                selected ? selected?.symptoms.map((obj) => obj.symptom) : []
              }
            />
          </div>

          {!selected || !selected.outcome ? (
            <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: "end" }}>
              <Popconfirm
                title={
                  selected
                    ? "Update screening record"
                    : "Create new screening record"
                }
                description={`Are you sure to ${
                  selected ? "update" : "create"
                } a screening record?`}
                onConfirm={() => form.submit()}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" htmlType="button" loading={loading}>
                  {selected
                    ? "Update Screening Record"
                    : "Create Screening Record"}
                </Button>
              </Popconfirm>
            </Form.Item>
          ) : null}
        </div>
      </Card>
    </Form>
  );
};

export default General;
