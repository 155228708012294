import { Button, Card, Form, Table } from "antd";
import FormInput from "Components/form/FormInput";
import FormSelect from "Components/form/FormSelect";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { REGIME, FREQUENCY, DURATION } from "Constants";
import { createDrug, getDrug, updateDrug, deleteDrugs } from "Api";

const DrugRegime = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [regime, setRegime] = useState([]);
  const [context, setContext] = useState(1);

  // Function to load drug regimes and refresh the table
  const loadDrugRegimes = () => {
    if (selected) {
      getDrug({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data;
        if (data) {
          setRegime(data);
        }
      });
    }
  };

  // Add, Update, or Delete function logic
  const onFinish = (values) => {
    const body = {
      ltbi_no: selected.ltbi_no,
      regime: values.regime,
      frequency: values.frequency,
      duration: values.duration,
      drug_Name: values.drug_Name,
      dose: values.dose,
      number_of_tabs: values.number_of_tabs,
    };

    if (context === 1) {
      // Create new drug regime
      createDrug(body).then((data) => {
        form.resetFields(); // Clear the form
        loadDrugRegimes(); // Refresh the table data
      });
    } else if (context === 2) {
      // Update existing drug regime
      body.id = form.getFieldValue("id");
      updateDrug(body).then((data) => {
        setContext(1); // Reset the context to create mode
        form.resetFields(); // Clear the form
        loadDrugRegimes(); // Refresh the table data
      });
    }
  };

  // Load the drug regimes when the selected patient changes
  useEffect(() => {
    if (selected) {
      loadDrugRegimes();
    }
  }, [selected]);

  // Delete a drug regime and update the table in real-time
  const handleDelete = (record) => {
    deleteDrugs({ id: record.id }).then((data) => {
      setRegime((prevRegime) =>
        prevRegime.filter((item) => item.id !== record.id)
      );
    });
  };

  const columns = [
    {
      title: "Regime",
      width: 100,
      dataIndex: "regime",
      key: "Regime",
    },
    {
      title: "Frequency",
      width: 100,
      dataIndex: "frequency",
      key: "Frequency",
    },
    {
      title: "Duration",
      width: 100,
      dataIndex: "duration",
      key: "Duration",
    },
    {
      title: "Drug Name",
      width: 100,
      dataIndex: "drug_Name",
      key: "Drug_name",
    },
    {
      title: "Drug dose",
      width: 100,
      dataIndex: "dose",
      key: "Drug_dose",
    },
    {
      title: "Number of tabs",
      width: 100,
      dataIndex: "number_of_tabs",
      key: "Number_of_tabs",
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (_, record) => (
        <div className="row">
          <div className="col-md-4">
            <Button
              style={{ color: "green" }}
              onClick={() => {
                setContext(2); // Set to update mode
                Object.entries(record).forEach(([key, value]) => {
                  form.setFieldValue(key, value); // Populate form with selected record
                });
              }}
            >
              Update
            </Button>
          </div>
          <div className="col-md-4">
            <Button
              style={{ color: "red", marginLeft: "10px" }}
              onClick={() => handleDelete(record)} // Handle delete action
            >
              Delete
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
        <FormInput name="id" type="hidden" />

          <div className="col-md-3">
            <FormSelect label="Regime" name="regime" options={REGIME} />
          </div>
          <div className="col-md-3">
            <FormSelect label="Frequency" name="frequency" options={FREQUENCY} />
          </div>
          <div className="col-md-3">
            <FormSelect label="Duration" name="duration" options={DURATION} />
          </div>
          <div className="col-md-3">
            <FormInput label="Name of the drug" name="drug_Name" />
          </div>
          <div className="col-md-3">
            <FormInput label="Drug dose" name="dose" />
          </div>
          <div className="col-md-3">
            <FormInput label="Number of tabs issued" name="number_of_tabs" />
          </div>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {context === 1 ? "Add" : "Update"}
            </Button>
          </Form.Item>
        </div>
        <div className="col-md-12">
          <Table
            columns={columns}
            dataSource={regime}
            rowKey="id" // Set a unique key for rows
            scroll={{ x: 400 }}
            sticky
            className="mt-3 w-full"
          />
        </div>
      </Card>
    </Form>
  );
};

export default DrugRegime;
