import { DatePicker, Form, Input, Switch } from "antd";

const FormInput = ({
  label = "",
  name = "",
  type = "text",
  options = [],
  disabled = false,
  required = false,
  onChange = () => {}, 
  extra = {},
  value = "", 
  readOnly = false, 
  className = "form-control" 

}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      valuePropName={type === "switch" ? "checked" : "value"}
    >
      {type === "hidden" ? (
        <Input disabled={disabled} required={required} hidden={true} id={name} name={name} type="hidden" value={value} className={className}
        />
      ) : null}
      {type === "text" ? (
        <Input disabled={disabled} required={required} onChange={(e) => onChange(e)} id={name} name={name} type="text" value={value} readOnly={readOnly} className={className}/>
      ) : null}
      {type === "number" ? (
        <Input disabled={disabled} required={required} maxLength={extra?.maxLength ?? 500} onChange={(e) => onChange(e)} id={name} name={name} type="text" value={value} readOnly={readOnly} className={className}/>
      ) : null}
      {type === "date" ? (
        <DatePicker
          disabled={disabled}
          required={required}
          onChange={(e) => onChange(e)} 
          style={{ width: "100%" }}
        />
      ) : null}
      {type === "switch" ? (
        <Switch disabled={disabled} required={required} />
      ) : null}
      {type === "textarea" ? (
        <Input.TextArea disabled={disabled} required={required} />
      ) : null}
    </Form.Item>
  );
};

export default FormInput;
