import React from "react";
import { Space, Table, Tag } from "antd";
import { Card } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import "../../../Styles/main.scss";

function AgeFifteentoFiftyFive() {
  return (
    <>
      <Card className="fos-card">
        <div className="d-flex justify-content-between">
          <div className="fos-card-heading font-bold">7582</div>
          <div className="fos-card-icon d-flex fos-purple">
            <BarChartOutlined />
          </div>
        </div>
        <p className="fos-card-description">Age Fifteen to Fifty Five </p>
      </Card>
    </>
  );
}
export default AgeFifteentoFiftyFive;
