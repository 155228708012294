import { Table, Button, Card, Form } from "antd";
import FormInput from "Components/form/FormInput";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import {
  createAdverseEffect,
  getAdverseEffect,
  updateAdverseEffects,
  deleteAdverseEffects,
} from "Api";

const AdverseEffect = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [effect, setEffect] = useState([]);
  const [context, setContext] = useState(1);

  // Function to load adverse effects and refresh the table
  const loadAdverseEffects = () => {
    if (selected) {
      getAdverseEffect({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data;
        if (data) {
          setEffect(data);
        }
      });
    }
  };

  // Add, Update, or Delete function
  const onFinish = (values) => {
    const body = {
      ltbi_no: selected.ltbi_no,
      effect: values.effect,
      drug: values.drug,
      action: values.action,
    };

    if (context === 1) {
      // Create new adverse effect
      createAdverseEffect(body).then((data) => {
        form.resetFields(); // Clear the form
        loadAdverseEffects(); // Refresh the table data
      });
    } else if (context === 2) {
      // Update existing adverse effect
      body.id = form.getFieldValue("id");
      updateAdverseEffects(body).then((data) => {
        setContext(1); // Reset the context to create mode
        form.resetFields(); // Clear the form
        loadAdverseEffects(); // Refresh the table data
      });
    }
  };

  // Load the adverse effects when the selected patient changes
  useEffect(() => {
    if (selected) {
      loadAdverseEffects();
    }
  }, [selected]);

  // Delete an adverse effect and update the table in real-time
  const handleDelete = (record) => {
    deleteAdverseEffects({ id: record.id }).then((data) => {
      setEffect((prevEffects) =>
        prevEffects.filter((item) => item.id !== record.id)
      );
    });
  };

  const columns = [
    {
      title: "Effect",
      width: 100,
      dataIndex: "effect",
      key: "Effect",
    },
    {
      title: "Drug",
      width: 100,
      dataIndex: "drug",
      key: "Drug",
    },
    {
      title: "Action",
      width: 100,
      dataIndex: "action",
      key: "Action",
    },
    {
      title: "Followup date",
      width: 100,
      dataIndex: "created",
      key: "created_at",
      render: (created) => {
        return new Date(created).toLocaleDateString();
      },
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (_, record) => (
        <div className="row">
          <div className="col-md-4">
            <Button
              style={{ color: "green" }}
              onClick={() => {
                setContext(2); // Set to update mode
                Object.entries(record).forEach(([key, value]) => {
                  form.setFieldValue(key, value); // Populate form with selected record
                });
              }}
            >
              Update
            </Button>
          </div>
          <div className="col-md-4">
            <Button
              style={{ color: "red", marginLeft: "10px" }}
              onClick={() => handleDelete(record)} // Handle delete action
            >
              Delete
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-4">
            <FormInput name="id" type="hidden" />
            <FormInput label="Effect" name="effect" />
            <FormInput label="Drug" name="drug" />
            <FormInput label="Action" name="action" />
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                {context === 1 ? "Add" : "Update"}
              </Button>
            </Form.Item>
          </div>
          <div className="col-md-8">
            <Table
              columns={columns}
              dataSource={effect}
              rowKey="id" // Set a unique key
              scroll={{ x: 400 }}
              sticky
              className="mt-3"
            />
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default AdverseEffect;
