import { Table, Button, Modal, Card, Input, Form, Steps } from "antd";
import FormInput from "Components/form/FormInput";
import { DISTRICTS } from "Constants";
import FormSelect from "Components/form/FormSelect";
import FormRadioGroup from "Components/form/FormRadioGroup";
import { GENDER } from "Constants";
import FormMulti from "Components/form/FormMulti";
import { CATEGORIES } from "Constants";
import { SYMPTOMS } from "Constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { createScreening } from "Api";
import dayjs from "dayjs";
import { CLOSE_CATEGORY } from "Constants";
import { CLINICAL_RISK } from "Constants";
import { VULNERABLE } from "Constants";
import { PRESENTPAST } from "Constants";
import { createHistory, getHistory } from "Api";

const History = ({ historyData, setHistoryData }) => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [presumptive, setPresumptive] = useState(0);

  // const onFinish = (values) => {
  //   console.log("Success:", values);
  //   if (selected) {
  //     // createHistory(body).then((data) => {
  //     //   console.log(data);
  //     // });
  //   } else {
  //     createHistory(values).then((out) => {
  //       console.log("Screening Out", out);
  //     });
  //   }
  // };
  const onFinish = (values) => {
    console.log("Success:", values);
    if (selected && values) {
      const body = {
        // screening_no: selected.screening_no,
        // pin: selected.pin,
        ltbi_no: selected.ltbi_no,
        tb_history: values.tb_history,
        medical_history: values.medical_history,
        surgical_history: values.surgical_history,
        drug_allergies: values.drug_allergies,
        food_allergies: values.food_allergies,
        smoking: values.smoking,
        alcohol: values.alcohol,
        illicit: values.illicit,
      };

      createHistory(body).then((data) => {
        console.log(data);
      });
    } else {
    }
  };

  // useEffect(() => {
  //   if (selected) {
  //     console.log("SELECTED", selected);
  //     Object.entries(selected).forEach(([key, value]) => {
  //       form.setFieldValue(key, key == "dob" ? dayjs(value) : value);
  //     });
  //     setPresumptive(selected["presumptive_category"]);
  //   } else {
  //     form.resetFields();
  //   }
  // }, [selected]);

  useEffect(() => {
    console.log({ selected });
    if (selected) {
      console.log("SELECTED", selected);
      getHistory({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data?.at(0);
        if (!data) {
          return;
        }
        console.log("HISTORY OUT", data);
        Object.entries(data).forEach(([key, value]) => {
          form.setFieldValue(key, value);
        });
      });
    } else {
      form.resetFields();
    }
  }, []);

  // useEffect(() => {
  //   form.setFieldValue("presumptive_category_sub", null);
  // }, [presumptive]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-3">
            <FormInput label="Past TB history" name="tb_history" />
          </div>
          <div className="col-md-3">
            <FormInput label="Past medical history" name="medical_history" />
          </div>
          <div className="col-md-3">
            <FormInput label="Past surgical history" name="surgical_history" />
          </div>
          <div className="col-md-3">
            <FormInput label="Drug allergies" name="drug_allergies" />
          </div>
          <div className="col-md-3">
            <FormInput label="Food allergies" name="food_allergies" />
          </div>
          <div className="col-md-3">
            <FormRadioGroup
              label="Smoking"
              name="smoking"
              options={PRESENTPAST}
            />
          </div>
          <div className="col-md-3">
            <FormRadioGroup
              label="Alcohol"
              name="alcohol"
              options={PRESENTPAST}
            />
          </div>
          <div className="col-md-3">
            <FormRadioGroup
              label="Illicit drugs"
              name="illicit"
              options={PRESENTPAST}
            />
          </div>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </div>
      </Card>
    </Form>
  );
};

export default History;
