import General from "Components/Pages/patients/General";
import PatientRecords from "Components/Pages/patients/PatientRecords";
import { Avatar, Button, Card, Row } from "antd";
import { UserOutlined, CloseCircleOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelected } from "Reducers/Patients";
import History from "Components/Pages/patients/History";
import Symptoms from "Components/Pages/patients/Symptoms";
import Examinations from "Components/Pages/patients/Examinations";
import Investigations from "Components/Pages/patients/Investigations";
import DrugRegime from "Components/Pages/patients/DrugRegime";
import AdverseEffect from "Components/Pages/patients/AdverseEffect";
import TreatmentCard from "Components/Pages/patients/TreatmentCard";
import Summary from "Components/Pages/patients/Summary";
import Outcome from "Components/Pages/patients/Outcome";

const PatientManager = () => {
  const selected = useSelector((state) => state.patients.selected);
  const dispatch = useDispatch();
  const buttons = [
    {
      type: 1,
      name: "Patients List",
      component: <PatientRecords />,
      bypass: true,
    },
    { type: 2, name: "General Information", component: <General /> },
    { type: 3, name: "History", component: <History /> },
    { type: 4, name: "Symptoms", component: <Symptoms /> },
    { type: 5, name: "Examinations", component: <Examinations /> },
    { type: 6, name: "Investigations", component: <Investigations /> },
    { type: 7, name: "Drug Regime", component: <DrugRegime /> },
    { type: 8, name: "Adverse Effects", component: <AdverseEffect /> },
    { type: 9, name: "Treatment Card", component: <TreatmentCard /> },
    { type: 10, name: "Summary", component: <Summary /> },
    { type: 11, name: "Outcome", component: <Outcome /> },
  ];
  const [type, setType] = React.useState(buttons[0]);

  useEffect(() => {
    console.log("Patient selected", selected);
  }, [selected]);

  return (
    <Row>
      <div className="col-md-12">
        <div style={buttonContainerStyle}>
          {buttons.map((btn) => {
            if (!btn.bypass && !selected) {
              return null;
            } else {
              return (
                <Button
                  style={type.type === btn.type ? btnStyle : btnStyleSel}
                  onClick={() => setType(btn)}
                >
                  {btn.name}
                </Button>
              );
            }
          })}
        </div>
      </div>
      {selected ? (
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4 mb-3">
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Avatar icon={<UserOutlined />} />
                  <div className="ms-3">{selected.name}</div>
                  <CloseCircleOutlined
                    style={{ padding: 10, color: "red", cursor: "pointer" }}
                    onClick={() => {
                      setType(buttons[0]);
                      dispatch(setSelected(null));
                    }}
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      ) : null}
      <div className="col-md-12">{type ? type.component : null}</div>
    </Row>
  );
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center",
};

const btnStyle = {
  width: "100%",
  height: "50px",
  background: "#084a87",
  color: "white",
  borderRadius: 0,
  border: "1px solid #60b2ff",
  padding: 10,
  margin: "10px 0",
  fontWeight: "bold",
  marginBottom: "30px",
};

const btnStyleSel = {
  width: "100%",
  height: "50px",
  background: "#60b2ff",
  color: "white",
  borderRadius: 0,
  border: "1px solid #60b2ff",
  padding: 10,
  margin: "10px 0",
  marginBottom: "30px",
};

export default PatientManager;
