import { Table, Button, Card, Form } from "antd";
import FormMulti from "Components/form/FormMulti";
import { SYMPTOMS } from "Constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";

import {
  createSymptoms,
  getSymptoms,
  updateSymptoms,
  deleteSymptoms,
} from "Api";

const Symptoms = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [context, setContext] = useState(1);
  const [symptom, setSymptom] = useState([]);

  const fetchSymptoms = () => {
    if (selected) {
      getSymptoms({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data;
        if (data) {
          setSymptom(data);
        }
      });
    }
  };

  const onFinish = (values) => {
    if (selected && values) {
      // Convert comma-separated string into an array if necessary
      let symptomsArray = values.symptom;

      if (typeof symptomsArray === "string") {
        symptomsArray = symptomsArray.split(",").map((item) => item.trim());
      }

      symptomsArray = Array.isArray(symptomsArray) ? symptomsArray : [symptomsArray]; // Ensure it's an array

      const body = {
        ltbi_no: selected.ltbi_no,
      };

      if (context === 1) {
        // Create a new symptom for each selected item in 'symptom'
        Promise.all(
          symptomsArray.map((symptomValue) => {
            const newBody = { ...body, symptom: symptomValue };
            return createSymptoms(newBody).then((data) => {
              return { ...newBody, created_at: new Date().toISOString() };
            });
          })
        ).then((newSymptoms) => {
          // update the table with newly created symptoms
          setSymptom((prevSymptoms) => [...prevSymptoms, ...newSymptoms]);
          form.resetFields();
        });
      } else if (context === 2) {
        // Update case: handle single symptom update logic as before
        body.id = form.getFieldValue("id");

        updateSymptoms(body).then((data) => {
          // Update the symptom in the table
          setSymptom((prevSymptoms) =>
            prevSymptoms.map((symp) => (symp.id === body.id ? { ...symp, ...body } : symp))
          );
          setContext(1);  // Reset the form to default context after update
        });
      }
    }
  };

  useEffect(() => {
    if (selected) {
      fetchSymptoms();
    } else {
      form.resetFields();
    }
  }, [selected]);

  const columns = [
    {
      title: "Symptom",
      width: 100,
      dataIndex: "symptom",
      key: "symptom",
    },
    {
      title: "Followup date",
      width: 100,
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (_, record) => (
        <div className="row">
          {/* <div className="col-md-4">
            <Button
              style={{ color: "green" }}
              onClick={() => {
                setContext(2);
                Object.entries(record).forEach(([key, value]) => {
                  form.setFieldValue(key, value);
                });
              }}
            >
              Update
            </Button>
          </div> */}
          <div className="col-md-4">
            <Button
              style={{ color: "red", marginLeft: "10px" }}
              onClick={() => {
                deleteSymptoms({ id: record.id }).then(() => {
                  // Remove the deleted symptom from the table
                  setSymptom((prevSymptoms) =>
                    prevSymptoms.filter((symp) => symp.id !== record.id)
                  );
                });
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-4">
            <div className="col-md-12">
              <FormMulti
                label="Symptoms"
                name="symptom"
                options={SYMPTOMS}
                setVal={(key, val) => {
                  form.setFieldValue(key, val);
                }}
                defaults={[]}
              />
            </div>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </div>
          <div className="col-md-8">
            <Table
              columns={columns}
              dataSource={symptom}
              rowKey="id"  // Add a unique identifier for the rows
              scroll={{ x: 400 }}
              sticky
              className="mt-3"
            />
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default Symptoms;
