import { Button, Card, Form, Table } from "antd";
import FormInput from "Components/form/FormInput";
import FormSelect from "Components/form/FormSelect";
import FormRadioGroup from "Components/form/FormRadioGroup";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { getPatientInvestigations, createPatientInvestigation } from "Api";
import dayjs from "dayjs";
import { INVESTIGATIONS } from "Constants";
import { CXR } from "Constants";
import { DatePicker } from "antd";

const Investigations = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected); // Get selected patient's data

  const unitRef = useRef(null);
  const resultCXRRef = useRef(null);

  const [investigations, setInvestigations] = useState([]);

  const loadInvestigations = () => {
    if (selected) {
      getPatientInvestigations({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data;
        if (data) {
          setInvestigations(data);
        }
      });
    }
  };

  const onFinish = (values) => {
    if (selected && values) {
      const body = {
        ltbi_no: selected.ltbi_no,
        type: values.investigation_type,
        investigation: values.report_name,
        report_no: values.report_no,
        test_date: values?.test_date ? values.test_date.format('YYYY-MM-DD') : null,
        result_date: values?.result_date ? values.result_date.format('YYYY-MM-DD') : null,
        result: values.result,
        unit: values.unit,
        grading: values.grading,
      };

      createPatientInvestigation(body).then((data) => {
        loadInvestigations()
        form.resetFields();
        console.log(data);
      });
    }
  };

  useEffect(() => {
    if (selected) {
      loadInvestigations()
    } else {
      form.resetFields();
    }
  }, [selected]);

  const handleEdit = (record) => {
    // Handle edit functionality here
    console.log("Edit record:", record);
  };

  const columns = [
    {
      title: "Report Name",
      dataIndex: "investigation",
      key: "investigation",
    },
    {
      title: "Report No.",
      dataIndex: "report_no",
      key: "report_no",
    },
    {
      title: "Test Date",
      dataIndex: "test_date",
      key: "test_date",
      render: (text) => text ? dayjs(text).format("YYYY-MM-DD") : '',
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
    },
    {
      title: "Result Date",
      dataIndex: "result_date",
      key: "result_date",
      render: (text) => text ? dayjs(text).format("YYYY-MM-DD") : '',
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => handleEdit(record)}>Edit</Button>
      ),
    },
  ];

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-3">
            <FormSelect
              label="Investigation Type"
              name="investigation_type"
              setVal={(val) => {
                unitRef.current.style.display =
                  val === "OTHER" ? "none" : "block";
                resultCXRRef.current.style.display =
                  val === "CXR" ? "block" : "none";
              }}
              options={INVESTIGATIONS}
            />
          </div>
          <div className="col-md-3">
            <FormInput label="Report Name" name="report_name" />
          </div>
          <div className="col-md-3">
            <FormInput label="Report Number" name="report_no" />
          </div>
          <div className="col-md-3">
            <Form.Item label="Test Date" name="test_date">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </div>
          <div className="col-md-3">
            <Form.Item label="Result Date" name="result_date">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </div>

          <div className="col-md-3">
            <FormInput label="Result" name="result" />
          </div>
          <div className="col-md-3" ref={resultCXRRef}>
            <FormRadioGroup label="Result" name="result" options={CXR} />
          </div>

          <div className="col-md-3" ref={unitRef}>
            <FormInput label="Unit" name="unit" />
          </div>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Card>

      {investigations && investigations.length > 0 ? (
        <Card className="p-2">
          <Table
            columns={columns}
            dataSource={investigations}
            scroll={{ x: 1400 }}
            sticky
            className="mt-3"
          />
        </Card>
      ) : null}
    </Form>
  );
};

export default Investigations;
