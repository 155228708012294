import { Form, Select } from "antd";

const FormSelect = ({ label = "", name = "", options = [], setVal = () => { } }) => {
    return <Form.Item label={label} name={name}>
        <Select showSearch
            onChange={setVal}
            optionFilterProp="children">
            {options.map((opt) => <Select.Option value={opt.value}>{opt.text}</Select.Option>)}
        </Select>
    </Form.Item>
}

export default FormSelect;