/* eslint-disable no-unused-vars */
import { Button, Card, Form, Table } from "antd";
import FormInput from "Components/form/FormInput";
import FormSelect from "Components/form/FormSelect";
import FormRadioGroup from "Components/form/FormRadioGroup";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { createScreening } from "Api";
import dayjs from "dayjs";
import { INVESTIGATIONS } from "Constants";
import { CXR } from "Constants";
import { addInvestigation, updateInvestigation } from "Api";
import { DatePicker, Popconfirm } from "antd";
import { setSelected } from "Reducers/Screening";
import { getScreeningItem } from "Api";
import { setAlert } from "Reducers/Layout";
const Investigations = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.screening.selected);
  const [presumptive, setPresumptive] = useState(0);
  const [investigations, setInvestigations] = useState([]);
  const [investigation, setInvestigation] = useState(null);
  const unitRef = useRef(null);
  const resultCXRRef = useRef(null);
  const resultRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onFinish = (values) => {
    if (investigation) {
      values.screening_no = selected.screening_no;
      values.id = investigation.id;
      updateInvestigation(values).then((out) => {
        console.log("Screening Out", out);
        if (out.status == 200 || out.status == 201) {
          dispatch(
            setAlert({ type: "success", message: "Investigation added" })
          );
          getScreeningItem(investigation.screening_no)
            .then((res) => {
              form.resetFields();
              if (res.data && res.data[0]) {
                dispatch(setSelected(res.data[0]));
              }
              setInvestigation(null);
              setLoading(false);
            })
            .catch((e) => {
              dispatch(
                setAlert({ type: "error", message: "Error fetching record" })
              );
              setInvestigation(null);
              setLoading(false);
            });
        } else {
          dispatch(setAlert({ type: "error", message: out.data?.message }));
          setInvestigation(null);
          setLoading(false);
        }
      });
    } else {
      values.screening_no = selected.screening_no;
      addInvestigation(values).then((out) => {
        console.log("Screening Out", out);
        if (out.status == 200 || out.status == 201) {
          dispatch(
            setAlert({ type: "success", message: "Investigation added" })
          );
          getScreeningItem(selected.screening_no)
            .then((res) => {
              form.resetFields();
              if (res.data && res.data[0]) {
                dispatch(setSelected(res.data[0]));
              }
              setLoading(false);
            })
            .catch((e) => {
              dispatch(
                setAlert({ type: "error", message: "Error fetching record" })
              );
              setLoading(false);
            });
        } else {
          dispatch(setAlert({ type: "error", message: out.data?.message }));
          setLoading(false);
        }
      });
    }
  };

  const columns = [
    {
      title: "Report Name",
      width: 150,
      dataIndex: "investigation",
      key: "investigation",
    },
    {
      title: "Report No.",
      width: 100,
      dataIndex: "report_no",
      key: "report_no",
    },
    {
      title: "Test Date",
      dataIndex: "test_date",
      key: "1",
      width: 100,
      render: (rec) => dayjs(rec).format("YYYY-MM-DD"),
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "2",
      width: 100,
      render: (rec,recs) => recs['type'] == 'CXR' ? CXR.filter((inv)=>inv.value == rec)[0]?.text : rec,
    },
    {
      title: "Result Date",
      dataIndex: "result_date",
      key: "3",
      width: 100,
      render: (rec) => dayjs(rec).format("YYYY-MM-DD"),
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (record) =>
        investigation != record ? (
          <Button onClick={() => setInvestigation(record)}>Select</Button>
        ) : (
          <Button onClick={() => setInvestigation(null)}>Deselect</Button>
        ),
    },
  ];

  useEffect(() => {
    resultCXRRef.current.style.display = "none";
  }, []);

  useEffect(() => {
    console.log("SELECTED",selected);
    
    if (selected) {
      Object.entries(selected).forEach(([key, value]) => {
        form.setFieldValue(key, key == "dob" ? dayjs(value) : value);
      });
      setPresumptive(selected["presumptive_category"]);
      setInvestigations(selected.investigations);
    } else {
      form.resetFields();
      setPresumptive(0);
      setInvestigations([]);
    }
  }, [selected]);

  useEffect(() => {
    form.setFieldValue("presumptive_category_sub", null);
  }, [presumptive]);

  useEffect(() => {
    if (investigation) {
      form.setFieldsValue({
        investigation_type: investigation.type,
        report_name: investigation.investigation,
        ...Object.entries(investigation)
          .filter(([key, _]) => key !== "investigation_type") // Exclude investigation_type
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]:
                key === "test_date" || key === "result_date"
                  ? value
                    ? dayjs(value)
                    : null
                  : value,
            }),
            {}
          ),
      });
      unitRef.current.style.display = investigation.type === "OTHER" ? "none" : "block";
      resultCXRRef.current.style.display = investigation.type === "CXR" ? "block" : "none";
      resultRef.current.style.display = investigation.type !== "CXR" ? "block" : "none";
      form.setFieldValue("result",investigation.result);
    } else {
      resultRef.current.style.display = "block";
      resultCXRRef.current.style.display = "none";
      form.resetFields();
    }
  }, [investigation]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-3">
            <FormSelect
              label="Investigation Type"
              name="investigation_type"
              setVal={(val) => {
                unitRef.current.style.display =
                  val === "OTHER" ? "none" : "block";
                resultCXRRef.current.style.display =
                  val === "CXR" ? "block" : "none";
                resultRef.current.style.display =
                  val !== "CXR" ? "block" : "none";
              }}
              options={INVESTIGATIONS}
            />
          </div>
          <div className="col-md-3">
            <FormInput label="Report Name" name="report_name" />
          </div>
          <div className="col-md-3">
            <FormInput label="Report Number" name="report_no" />
          </div>
          <div className="col-md-3">
            <Form.Item label="Test Date" name="test_date">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </div>
          <div className="col-md-3">
            <Form.Item label="Result Date" name="result_date">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </div>

          <div className="col-md-3" ref={resultRef}>
            <FormInput label="Result" name="result" />
          </div>
          <div className="col-md-3" ref={resultCXRRef}>
            <FormRadioGroup label="Result" name="result" options={CXR} />
          </div>

          <div className="col-md-3" ref={unitRef}>
            <FormInput label="Unit" name="unit" />
          </div>

          {(!selected.outcome) ? <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'end' }}>
            <Popconfirm
              title={investigation ? "Update investigation" : "Add new investigation"}
              description={`Are you sure to ${investigation ? "update" : "add"} this investigation?`}
              onConfirm={() => form.submit()}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" htmlType="button" loading={loading}>
                {investigation ? "Update Investigation" : "Add Investigation"}
              </Button>
            </Popconfirm>
          </Form.Item> : null}
        </div>
      </Card>

      {investigations && investigations.length > 0 ? (
        <Card className="p-2">
          <Table
            columns={columns}
            dataSource={investigations}
            scroll={{ x: 1400 }}
            sticky
            className="mt-3"
          />
        </Card>
      ) : null}
    </Form>
  );
};

export default Investigations;
