import { getPatientReport } from "Api";
import React, { useEffect, useRef, useState } from "react";

import { Button, Card, Col, Row } from "antd";
import Table from "react-bootstrap/Table";
import { useReactToPrint } from "react-to-print";
import image1 from "../../../Assests/logo_lk.png";
import image2 from "../../../Assests/LTBI.png";

const TreatmentRegReport = () => {
  const [rows, setRows] = useState([]);
  const componentRef = useRef();
  // const handlePrint = props.handlePrint;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getPatientReport().then((out) => {
      console.log("SCREENING REP", out);
      setRows([...out.data]);
    });
  }, []);

  const FieldBody = React.forwardRef((props, ref) => {
    return (
      <>
        {" "}
        <Card>
          <div className="col-md-12" ref={ref}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                color="danger"
                onClick={handlePrint}
              >
                Print Report
              </Button>
            </div>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col>
                <img src={image1} alt="Image 1" style={{ height: 100 }} />
              </Col>
              <div style={{ textAlign: "center", marginRight: "10px" }}>
                <h5 style={{ margin: 0 }}>
                  National Programme for Tuberculosis Control and Chest Diseases
                </h5>
                <p style={{ margin: 0 }}>District LTBI Treatment Register</p>
              </div>
              <Col>
                <img src={image2} alt="Image 2" style={{ height: 100 }} />
              </Col>
            </Row>
            {/* <div className="text-center">
            <h5>
              National Programme for Tuberculosis Control and Chest Diseases
            </h5>
            <p>District LTBI Treatment Register</p>
          </div> */}
            <div
              className="col-md-08"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>

            <div style={{ margin: "10px 10px 10px 25px" }}>
              <Row>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th rowSpan={2}>Date of registration</th>
                      <th rowSpan={2}>District LTBI No</th>
                      <th rowSpan={2}>Full name</th>
                      <th rowSpan={2}>Complete address</th>
                      <th rowSpan={2}>Age</th>
                      <th rowSpan={2}>Sex</th>
                      <th colSpan={5}>Latent TB category</th>
                      <th colSpan={4}>Treatment Regimen4</th>
                      <th colSpan={6}>Outcome of LTBI treatment5</th>
                      <th rowSpan={2}>Remarks 6</th>
                    </tr>
                    <tr>
                      <th>PLHIV</th>
                      <th>Close contact 1</th>
                      <th>If close contact, DTB no of index patient</th>
                      <th>Clinical risk group 2</th>
                      <th>Other vulnerable group 3</th>
                      <th>3HP</th>
                      <th>3HR</th>
                      <th>6H</th>
                      <th>Other (specify)</th>
                      <th>Treatment completed</th>
                      <th>Treatment failed</th>
                      <th>Died</th>
                      <th>Lost to follow up</th>
                      {/* <th>Discontinued due to toxicity</th> */}
                      <th>Treatment Discontinued due to clinical reasons</th>
                      <th>Not Evaluated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row) => {
                      return (
                        <tr>
                          <td>{row.date}</td>
                          <td>{row.screening_no}</td>
                          <td>{row.name}</td>
                          <td>{row.address}</td>
                          <td>{row.age}</td>
                          <td>{row.sex}</td>
                          <td>{row.plhiv}</td>
                          <td>{row.close}</td>
                          <td>{row.index_patient}</td>
                          <td>{row.clinical}</td>
                          <td>{row.vulnerable}</td>
                          <td>{row.sputum}</td>
                          <td>{row.cxr}</td>
                          <td>{row.xpert}</td>
                          <td>{row.other_reason}</td>
                          <td>{row.tb}</td>
                          <td>{row.tst}</td>
                          <td>{row.igra}</td>
                          <td>{row.ltbi}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            </div>

            <div
              className="col-md-08"
              sm={8}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
            <div style={{ margin: "10px 10px 50px 10px", textAlign: "center" }}>
              <p style={{ margin: 0 }}>
                1: Enter “H” for household close contacts and “NH” for
                non-household close contacts
              </p>
              <p style={{ margin: 0 }}>
                2: Enter “TNF” for patients on treatment with anti-TNF alpha,
                “NTNF” for patients on treatment with non-anti-TNF alpha, “HD”
                for patients receiving dialysis, “SOT” for patients preparing
                for solid organ transplantation, “HSCT” for patient preparing
                for hematopoietic stem cell transplantation, “SIL” for patients
                with silicosis
              </p>
              <p style={{ margin: 0 }}>
                3: Enter “H” for health care workers and “P” for prisoners
              </p>
              <p style={{ margin: 0 }}>
                4. Enter Date treatment started in the relevant regime column
              </p>
              <p style={{ margin: 0 }}>
                5. Enter Date of outcome in the relevant outcome column
              </p>
              <p style={{ margin: 0 }}>6. Enter Reasons for not evaluated</p>
            </div>
          </div>
        </Card>
      </>
    );
  });
  return (
    <FieldBody ref={componentRef} handlePrint={handlePrint} /> // Render the FieldBody component
  );
};

export default TreatmentRegReport;
