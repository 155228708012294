// import { CButton, CCol, CRow } from "@coreui/react";
import { Button, Col, Row } from "antd";
import React from "react";
// import CaseFindReport from "../CaseFindReport";
import OutcomeReport from "Components/Pages/reports/OutcomeReport";
// import TreatmentRegReport from "../TreatmentRegReport";
import ScreeningRegReport from "Components/Pages/reports/ScreeningRegReport";
import CaseFindReport from "Components/Pages/reports/CaseFindReport";
import TreatmentRegReport from "Components/Pages/reports/TreatmentRegReport";

const Reports = () => {
  const [type, setType] = React.useState(1);

  return (
    <Row>
      <div className="col-md-12">
        <div style={buttonContainerStyle}>
          <Button
            style={type === 1 ? btnStyle : btnStyleSel}
            onClick={() => setType(1)}
          >
            Screening Register Report
          </Button>
          <Button
            style={type === 2 ? btnStyle : btnStyleSel}
            onClick={() => setType(2)}
          >
            Treatment Register Report
          </Button>
          <Button
            style={type === 3 ? btnStyle : btnStyleSel}
            onClick={() => setType(3)}
          >
            Case Finding Report
          </Button>
          <Button
            style={type === 4 ? btnStyle : btnStyleSel}
            onClick={() => setType(4)}
          >
            Outcome Report
          </Button>
        </div>
      </div>
      <div className="col-md-12">
        {/* <Col md={12}> */}
        {type === 1 ? <ScreeningRegReport /> : null}
        {type === 2 ? <TreatmentRegReport /> : null}
        {type === 3 ? <CaseFindReport /> : null}
        {type === 4 ? <OutcomeReport /> : null}
      </div>
    </Row>
  );
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center",
};

const btnStyle = {
  width: "100%",
  height: "50px",
  background: "#084a87",
  color: "white",
  borderRadius: 0,
  border: "1px solid #60b2ff",
  padding: 10,
  margin: "10px 0",
  marginBottom: "30px",
};

const btnStyleSel = {
  width: "100%",
  height: "50px",
  background: "#60b2ff",
  color: "white",
  borderRadius: 0,
  border: "1px solid #60b2ff",
  padding: 10,
  margin: "10px 0",
  fontWeight: "bold",
  marginBottom: "30px",
};

export default Reports;