import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Card, Input, Form, Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import General from "Components/Pages/screening/General";
import Investigations from "Components/Pages/screening/Investigations";
import { CloseCircleOutlined } from "@ant-design/icons";
import { setSelected } from "Reducers/Screening";
import Outcome from "Components/Pages/screening/Outcome";

function ScreeningRegister() {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [current, setCurrent] = useState(0);
  const selected = useSelector((state) => state.screening.selected);
  const dispatch = useDispatch();

  const onChange = (value) => {
    console.log("onChange:", value);
    setCurrent(value);
  };

  const description = "This is a description.";
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div className="fos-heading">Screening</div>
          <div className="fos-heading-small">
            {selected ? selected.name : "Register"}
            {selected ? (
              <CloseCircleOutlined
                style={{ padding: 10, color: "red", cursor: "pointer" }}
                onClick={() => {
                  dispatch(setSelected(null));
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <Card>
        <Steps
          current={current}
          onChange={onChange}
          items={[
            {
              title: "General",
            },
            {
              title: "Investigations",
              disabled: !selected
            },
            {
              title: "Outcome",
              disabled: !selected
            },
          ]}
        />
      </Card>
      {current === 0 ? <General /> : null}
      {current === 1 ? <Investigations /> : null}
      {current === 2 ? <Outcome /> : null}
    </div>
  );
}

export default ScreeningRegister;
