import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    records: [],
    selected: null,
}

export const layoutSlice = createSlice({
    name: 'screening',
    initialState,
    reducers: {
        setRecords: (state, action) => {
            state.records = action.payload
        },
        setSelected: (state, action) => {
            state.selected = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setRecords, setSelected } = layoutSlice.actions

export default layoutSlice.reducer