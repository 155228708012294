import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined, BookFilled } from '@ant-design/icons';
import { Card } from 'antd';
import { login } from '../Api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../Reducers/Layout';
import { items } from 'Constants';

function Login() {
    const dispatch = useDispatch()
    const onFinish = (values) => {
        login(values).then((val) => {
            if (val.status == 200) {
                dispatch(setAlert({ type: 'success', message: 'Login successful' }));
                setTimeout(() => {
                    window.localStorage.setItem('token', val.data.token);
                    window.localStorage.setItem('username', val.data.username);
                    window.localStorage.setItem('permissions', val.data.permissions.toString());
                    for(let i = 0; i < items.length; i++) {
                        if(items[i]) {
                            window.location.href = items[i].url;
                            break;
                        }
                    }
                },3000);
            } else {
                dispatch(setAlert({ type: 'error', message: 'Login unsuccesful' }));
            }
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (

        <div className='row'>
            <div className='col-md-6' style={{ background: '#12D66C', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <BookFilled style={{ fontSize: 150, color: 'white', marginBottom: 20 }} />
                <h2 style={{ color: 'white' }}>LTBI</h2>
            </div>
            <div className='col-md-6'>
                <Card className="p-2" style={{ height: '100vh' }}>
                    <div className='row' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '95vh' }}>
                        <div className='col-md-6'>
                            <div className="fos-card-heading font-bold" style={{ marginBottom: 20 }}>
                                Login
                            </div>
                            <Form
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                layout='vertical'
                            >
                                <Form.Item
                                    label='Email'
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email',
                                        },
                                    ]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                                </Form.Item>

                                <Form.Item
                                    label='Password'
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
                                </Form.Item>

                                {/* <a href='/register'>
                                    New user registration!
                                </a> */}

                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                    style={{ marginTop: 20 }}
                                >
                                    <Button type="primary" htmlType="submit" className='fos-btn-primary mb-1 ml-4'>
                                        Login
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default Login;