import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Card, Input, Progress, Space } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { getScreenings, createPatient } from "../Api";
import { useDispatch } from "react-redux";
import { setRecords, setSelected } from "../Reducers/Screening";
import { Link } from "react-router-dom";
import { setAlert } from "Reducers/Layout";

function ScreeningRecords() {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowsAll, setRowsAll] = useState([]);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const dispatch = useDispatch();

  const fetchRecords = () => {
    getScreenings().then((out) => {
      setRows(out.data);
      setRowsAll(out.data);
      dispatch(setRecords(out.data));
    });
  }

  useEffect(() => {
    dispatch(setAlert({ type: 'info', message: 'Loading' }));
    fetchRecords();
  }, []);

  const handleModalClose = () => {
    setSelectedRow(null);
    setModalVisible(false);
  };
  const columns = [
    {
      title: "Screening No",
      width: 100,
      dataIndex: "screening_no",
      key: "screening_no",
    },
    {
      title: "Name",
      width: 150,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "permanent_address",
      key: "1",
      width: 150,
    },
    {
      title: "Mobile",
      dataIndex: "mobile_phone",
      key: "2",
      width: 100,
    },
    {
      title: "Stage",
      key: "stage",
      width: 35,
      render: (_, record) => (
        <Progress
          size={30}
          type="circle"
          percent={record.stage * (100 / 3)}
          showInfo={false}
        />
      ),
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (_, record) => (
        <div className="row">
          <Space>
            <Link
              onClick={() => {
                // console.log(record);
                dispatch(setSelected(record));
              }}
              to="/screening_register"
            >
              <Button>Select</Button>
            </Link>
            {record.outcome == 2 && !record.ltbi_no ? <Button color="secondary" onClick={() => {
              dispatch(setAlert({ type: 'info', message: 'Loading' }));
              createPatient(record).then((out) => {
                if (out.status == 200) {
                  dispatch(setAlert({ type: 'success', message: 'Enrolled successfully' }));
                  fetchRecords();
                } else {
                  dispatch(setAlert({ type: 'error', message: 'Enrolled unsuccessfull' }));
                }
              }).catch(e => {
                dispatch(setAlert({ type: 'error', message: 'Enrolled unsuccessfull' }));
              });
            }}>Enroll</Button> : null}
            {record.ltbi_no ? <Button color="secondary">Enrolled</Button> : null}
          </Space>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div className="fos-heading">Screening</div>
          <div className="fos-heading-small">Records</div>
        </div>
      </div>
      <Card className="p-2">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <div>
                  <div className="fos-label mt-2">Name</div>
                  <div className="mt-1">
                    <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <div className="fos-label mt-2">Mobile</div>
                  <div className="mt-1">
                    <Input placeholder="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mt-4">
              <Button className="fos-btn-border" onClick={() => {
                setRows(rowsAll);
                setMobile("");
                setName("");
              }}>Clear</Button>
              <Button className="fos-btn-primary ml-4" onClick={() => {
                const recs = rowsAll.filter((rec) =>
                  rec.name.toString().toLowerCase().includes(name.toString().toLowerCase()) &&
                  rec.mobile_phone.toString().toLowerCase().includes(mobile.toString().toLowerCase()))
                setRows(recs);
              }}>Search</Button>
            </div>
          </div>
        </div>
      </Card>
      <Table
        columns={columns}
        dataSource={rows}
        scroll={{ x: 1400 }}
        sticky
        className="mt-3"
      />
    </div>
  );
}

export default ScreeningRecords;
