import { Table, Button, Modal, Card, Input, Form, Steps } from "antd";
import FormInput from "Components/form/FormInput";
import { DISTRICTS } from "Constants";
import FormSelect from "Components/form/FormSelect";
import FormRadioGroup from "Components/form/FormRadioGroup";
import { GENDER } from "Constants";
import FormMulti from "Components/form/FormMulti";
import { CATEGORIES } from "Constants";
import { SYMPTOMS } from "Constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { createScreening } from "Api";
import dayjs from "dayjs";
import { CLOSE_CATEGORY } from "Constants";
import { CLINICAL_RISK } from "Constants";
import { VULNERABLE } from "Constants";

const General = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [presumptive, setPresumptive] = useState(0);

  const onFinish = (values) => {
    console.log("Success:", values);
    if (selected) {
    } else {
    }
  };

  // useEffect(() => {
  //   if (presumptive == 1) {
  //     form.setFieldValue("presumptive_category_sub", 1);
  //   }
  // }, [presumptive]);

  // useEffect(() => {
  //   if (selected) {
  //     console.log("SELECTED", selected);
  //     Object.entries(selected).forEach(([key, value]) => {
  //       form.setFieldValue(key, key == "dob" ? dayjs(value) : value);
  //     });
  //     setPresumptive(selected["presumptive_category"]);
  //   } else {
  //     form.resetFields();
  //   }
  // }, [selected]);

  // useEffect(() => {
  //   form.setFieldValue("presumptive_category_sub", null);
  // }, [presumptive]);
  useEffect(() => {
    if (selected) {
      console.log("SELECTED", selected);
      Object.entries(selected).forEach(([key, value]) => {
        form.setFieldValue(key, key === "dob" ? dayjs(value) : value);
      });
      setPresumptive(selected["presumptive_category"]);

      // for symptoms as it is an array object
      const symptoms = selected.symptoms;
      console.log(symptoms);
      symptoms?.forEach((s) => form.setFieldValue("symptoms", s.symptom));
    } else {
      form.resetFields();
    }
  }, [selected]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-3">
            <FormInput label="District LTBI Number" name="ltbi_no" disabled />
          </div>
          <div className="col-md-3">
            <FormInput
              label="District LTBI Screening Number"
              name="screening_no"
              disabled
            />
          </div>
          <div className="col-md-3">
            <FormInput label="Standard Card Number" name="standard_card_no" />
          </div>
          <div className="col-md-3">
            <FormInput label="Patient identification number" name="pin" />
          </div>
          <div className="col-md-3">
            <FormSelect label="District" name="district" options={DISTRICTS} />
          </div>
          <div className="col-md-3">
            <FormInput label="Name" name="name" required />
          </div>
          <div className="col-md-3">
            <FormRadioGroup label="Sex" name="sex" options={GENDER} />
          </div>
          <div className="col-md-3">
            <FormInput label="Date of birth" name="dob" type="date" />
          </div>
          <div className="col-md-3">
            <FormInput label="Past TB History" name="past_tb" />
          </div>
          <div className="col-md-3">
            <FormInput label="Mobile" name="mobile_phone" type="number" />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Home phone number"
              name="home_phone"
              type="number"
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Guardian phone number"
              name="guardian_phone"
              type="number"
            />
          </div>
          <div className="col-md-6">
            <FormInput label="Permanent address" name="permanent_address" />
          </div>
          <div className="col-md-6">
            <FormInput label="Residential address" name="residential_address" />
          </div>
          <div className="col-md-3">
            <FormSelect
              label="Presumptive LTBI Category"
              name="presumptive_category"
              setVal={(val) => {
                setPresumptive(val);
              }}
              options={CATEGORIES}
            />
          </div>
          {presumptive != 1 && presumptive != 5 ? (
            <div className="col-md-3">
              <FormSelect
                label="Presumptive LTBI Category"
                name="presumptive_category_sub"
                options={
                  presumptive == 2
                    ? CLOSE_CATEGORY
                    : presumptive == 3
                    ? CLINICAL_RISK
                    : VULNERABLE
                }
              />
            </div>
          ) : null}
          {presumptive == 2 ? (
            <div className="col-md-3">
              <FormInput label="DTB No. of TB patient" name="tb_no" required />
            </div>
          ) : null}
          {presumptive == 5 ? (
            <div className="col-md-3">
              <FormInput label="Other reason" name="other_reason" required />
            </div>
          ) : null}
          <div className="col-md-12">
          <FormMulti
              label="Symptoms"
              name="symptoms"
              options={SYMPTOMS}
              setVal={(key, val) => {
                form.setFieldValue(key, val);
              }}
              defaults={
                selected ? selected?.symptoms?.map((obj) => obj.symptom) : []
              }
            />
          </div>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Card>
    </Form>
  );
};

export default General;
